import {
  activateUser,
  login,
  logout,
  lostPassword,
  resetPassword,
  signup,
  user,
  isAuthorized,
  init,
  client,
} from "~/utilities/auth"

export function useAuth() {
  return {
    client,
    user,
    init,
    signup,
    activateUser,
    resetPassword,
    lostPassword,
    login,
    logout,
    isAuthorized,
  }
}
