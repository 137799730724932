export const article__template: Record<string, string> = {
  "0": "Text",
  "1": "Video",
  "2": "Podcast",
  "3": "Quiz",
  "4": "Image with text",
  "5": "Text with image",
  "6": "Math task",
  "7": "Math task with image",
}

export const article__image_size: Record<string, string> = {
  "0": "Large",
  "1": "Medium",
  "2": "Small",
}

export const article__bg: Record<string, string> = {
  "0": "default",
  "1": "portal-indigo-100",
  "2": "portal-indigo-200",
  "3": "portal-indigo-300",
  "4": "portal-indigo-400",
  "5": "portal-indigo-500",
  "6": "portal-indigo-600",
  "7": "portal-indigo-700",
  "8": "portal-indigo-800",
  "9": "portal-indigo-900",
  "10": "red-20",
  "11": "red-40",
  "12": "red-60",
  "13": "red-80",
  "14": "red-100",
  "15": "paper",
  "16": "dark-blue-20",
  "17": "dark-blue-40",
  "18": "dark-blue-60",
  "19": "dark-blue-80",
  "20": "dark-blue-100",
  "21": "green-20",
  "22": "green-40",
  "23": "green-60",
  "24": "green-80",
  "25": "green-100",
  "26": "purple-20",
  "27": "purple-40",
  "28": "purple-60",
  "29": "purple-80",
  "30": "purple-100",
  "31": "yellow-20",
  "32": "yellow-40",
  "33": "yellow-60",
  "34": "yellow-80",
  "35": "yellow-100",
  "36": "teal-20",
  "37": "teal-40",
  "38": "teal-60",
  "39": "teal-80",
  "40": "teal-100",
  "42": "black",
  "43": "white",
}

export const article_color: Record<string, string> = {
  "0": "default",
  "1": "portal-indigo-100",
  "2": "portal-indigo-200",
  "3": "portal-indigo-300",
  "4": "portal-indigo-400",
  "5": "portal-indigo-500",
  "6": "portal-indigo-600",
  "7": "portal-indigo-700",
  "8": "portal-indigo-800",
  "9": "portal-indigo-900",
  "10": "red-20",
  "11": "red-40",
  "12": "red-60",
  "13": "red-80",
  "14": "red-100",
  "15": "grey-20",
  "16": "grey-40",
  "17": "grey-60",
  "18": "grey-80",
  "19": "grey-100",
  "20": "paper",
  "21": "dark-blue-20",
  "22": "dark-blue-40",
  "23": "dark-blue-60",
  "24": "dark-blue-80",
  "25": "dark-blue-100",
  "26": "green-20",
  "27": "green-40",
  "28": "green-60",
  "29": "green-80",
  "30": "green-100",
  "31": "purple-20",
  "32": "purple-40",
  "33": "purple-60",
  "34": "purple-80",
  "35": "purple-100",
  "36": "yellow-20",
  "37": "yellow-40",
  "38": "yellow-60",
  "39": "yellow-80",
  "40": "yellow-100",
  "41": "teal-20",
  "42": "teal-40",
  "43": "teal-60",
  "44": "teal-80",
  "45": "teal-100",
  "46": "black",
  "47": "white",
}
