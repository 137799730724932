export const useProductValidTo = async () => {
  const store = useProductVariantsStore()
  const licence = useLicenceStore()

  const EAN = ref(store.activeVariant?.ean)

  const licenceData = computed(() => licence.licenceData)
  const getValidTo = () =>
    licenceData.value?.find(i => i.ean === EAN.value)?.endDate || ""

  const validTo = ref(getValidTo())

  watch(
    () => store.activeVariant,
    () => {
      EAN.value = store.activeVariant?.ean
      validTo.value = getValidTo()
    },
  )

  return validTo
}
