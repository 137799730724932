const prod = import.meta.env.MODE === "production"
const ssr = import.meta.env.SSR

const sources = [
  "https://js-eu1.hs-scripts.com/25807489.js",
  "https://cdn-cookieyes.com/client_data/4b9a2b0d9cdf9d97e17edfe6/script.js",
]

function setScript(src: string) {
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.setAttribute("id", "hs-script-loader")
  script.setAttribute("async", "async")
  script.setAttribute("defer", "defer")

  script.src = src

  document.head.appendChild(script)
}

if (prod && !ssr) sources.forEach(i => setScript(i))
