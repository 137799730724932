<script setup lang="ts">
const emit = defineEmits(["speedEvent"])

const playBackSpeedData = [
  {
    label: "0,75 X",
    value: 0.75,
  },
  {
    label: "1 X",
    value: 1,
  },
  {
    label: "1,25 X",
    value: 1.25,
  },
  {
    label: "1,5 X",
    value: 1.5,
  },
  {
    label: "1,75 X",
    value: 1.75,
  },
  {
    label: "2 X",
    value: 2,
  },
]

const show = ref(false)
const playbackSpeed = ref(1)

const handleSpeed = (speed: number) => {
  playbackSpeed.value = speed
  show.value = false
  emit("speedEvent", playbackSpeed.value)
}
</script>

<template>
  <div class="relative self-center playback-speed-wrapper">
    <UButtonNew theme="white" variant="quite" @click="show = !show">
      {{ playbackSpeed }}x</UButtonNew
    >
    <UAudioPopup v-model="show" class="playback-speed-popup">
      <ul class="text-black">
        <li v-for="(i, key) in playBackSpeedData" :key="key">
          <u-button
            variant="quite"
            class="text-right"
            @click="handleSpeed(i.value)"
          >
            {{ i.label }}
          </u-button>
        </li>
      </ul>
    </UAudioPopup>
  </div>
</template>

<style scoped lang="postcss">
.playback-speed-wrapper {
  @apply md:block;
  .speed-btn {
    @apply font-national2 text-[18px] leading-normal text-platform-paper;
    @apply rounded-full border-2 border-solid border-platform-paper px-4 py-1;
  }
  .playback-speed-popup {
    @apply left-[-6rem] top-[-30rem] w-40 rounded-[20px] bg-white py-1;
    ul {
      @apply h-auto;
      li {
        @apply border-b-2
        border-solid border-[#BEB9B2]
        border-opacity-20 py-3
        pr-5 font-national2 text-[18px] leading-normal last:border-none;
        button {
          @apply w-full text-right;
        }
      }
    }
  }
}
</style>
