import type { PageUrlAliasIbexaData } from "~/types/ibexa.pages"

import GraphqlPagesDocument from "~/graphql/documents/pages"
import { graphqlClient } from "~/graphql/client"
import type { PageUrlAlias } from "~/types/pages"

export default async (): Promise<PageUrlAlias[]> => {
  const { data } = (await graphqlClient({
    query: GraphqlPagesDocument,
  })) as PageUrlAliasIbexaData

  return data.content.pages.edges
    .filter(({ node }) => node._location.urlAliases.length > 0)
    .map(({ node }) => ({
      title: node.title,
      url: node._location.urlAliases[0].path,
      priority: node._location.priority,
    }))
}
