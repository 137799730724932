<script setup>
const backgroundColor = useBackgroundColor()
backgroundColor.value = "var(--theme-lightest)"
</script>

<template>
  <div
    class="text scroll-smooth bg-[--color-platform-paper] text-[#1A3985]"
  >
    <!-- Header -->
    <UHeader back-button />
    <!-- Main content -->
    <main class="py-4 lg:pt-0">
      <router-view />
    </main>

    <UMarketingFooter class="bg-dark-blue-darker" />
  </div>
</template>
