<script setup lang="ts">
import { isAuthorized } from "~/utilities/auth"

const props = defineProps({
  path: {
    type: Array as PropType<string[]>,
    required: true,
    default: () => [],
  },
})

const loginPageUrl = useLoginPageUrl()
const products = useProductVariantsStore()
const route = useRoute()

const isProfilePageActive = computed(() =>
  route.path.includes("min-side"),
)

const urlPrefix = `/produkter/${props.path.filter(Boolean).join("/")}`
</script>

<template>
  <div
    class="flex w-full items-center justify-end pt-12 xl:pt-14 <md:!hidden"
  >
    <UButtonNew
      v-if="isAuthorized"
      id="min-side"
      data-pendo="min-side-desktop"
      :to="
        isProfilePageActive
          ? `${urlPrefix}${route.hash}`
          : `${urlPrefix}/min-side${route.hash}`
      "
      theme="light"
      size="sm"
      :active="isProfilePageActive"
    >
      <template #prepend>
        <u-icon name="user" size="sm" />
      </template>
      Min side
    </UButtonNew>
    <UButtonNew
      v-else
      id="logg-in"
      variant="outline"
      class="mt-3 <md:!hidden"
      theme="light"
      size="sm"
      :to="
        products.activeVariant
          ? `${loginPageUrl}?fromPath=${route.path}`
          : loginPageUrl
      "
    >
      <template #prepend>
        <UIcon name="user" />
      </template>
      Logg inn
    </UButtonNew>
  </div>
</template>
