import type {
  ArticleNodeContent,
  ArticleNodeContentData,
  DestinationContent,
} from "~/types/ibexa.article"
import { ArticleChildrenMathTaskNode } from "~/types/ibexa.article"
import type { Article, Relation } from "~/types/article"

import createVideo, { formatVideoData } from "./create-video"
import createAudio from "./create-audio"
import createQuiz from "./create-quiz"
import createMathTask from "./create-mathtask"
import brightnessFormat from "~/utilities/brightness-format"
import { createHtmlFormatter } from "~/utilities/dom-parsing"
import type { ContentArticle } from "~/models/Content/ContentArticle"
import {
  onlyChar,
  capitalizeChar,
  camalize,
} from "~/utilities/composables"

/**
 *
 * @param node
 * @returns
 */
export function formatData(
  node: ArticleNodeContent,
  restNode: ContentArticle,
): Article {
  const {
    id,
    hidden,
    priority,
    content,
    parentLocationId,
    parentLocation,
  } = node

  const {
    title,
    titleHtml,
    shortTitle,
    template,
    intro,
    body,
    relatedArticles,
    _type: { identifier },
    _url,
    bg,
    color,
    image,
    imageCaption,
    imageSize,
    hasPictureFrame,
    pictureColor,
    pictureText,
    _info: {
      currentVersion: {
        versionNumber,
        creationDate,
        modificationDate,
      },
      relations,
      locations,
    },
  } = content as ArticleNodeContentData

  //const { title, titleHtml, shortTitle } = restNode as ContentArticle

  const [video] = createVideo(relatedArticles)
  const [audio] = createAudio(relatedArticles)
  const [quiz] = createQuiz(relatedArticles)

  const articleMathTaskChildren =
    locations[0]?.children?.edges?.filter(
      (e): e is { node: ArticleChildrenMathTaskNode } =>
        "id" in e.node &&
        e.node.content?._type?.identifier === "math_task",
    ) ?? []

  const mathTask = createMathTask(id, articleMathTaskChildren)

  const childRelations = articleMathTaskChildren
    .map(e => e.node.content._info?.relations ?? [])
    .flat()

  const formattedRelations = formatDestinationContentToRelation(
    [...relations, ...childRelations].map(r => r.destinationContent),
  )

  /** Function to format links and references. This also mutates relations! */
  const formatHtml = createHtmlFormatter(formattedRelations)

  const assignmentsFormatted = mathTask?.assignments.map(a => ({
    ...a,
    text: formatHtml(a.text),
  }))

  return {
    id,
    hidden,
    priority,
    parentLocationId,
    parentLocation,
    template:
      "U" + onlyChar(capitalizeChar(camalize(template))) + "Article",
    title,
    titleHtml,
    shortTitle,
    intro: {
      html: formatHtml(intro.html5),
      plaintext: intro.plaintext,
    },
    body: {
      html: formatHtml(body.html5),
      plaintext: body.plaintext,
    },
    img: {
      id: image?.id,
      src: image?.variation?.uri,
      alt: image?.alternativeText,
      focalPointX: image?.additionalData?.focalPointX,
      focalPointY: image?.additionalData?.focalPointY,
      width: image?.width,
      height: image?.height,
    },
    imageCaption: {
      html: imageCaption?.html5 === "\n" ? "" : imageCaption?.html5,
    },
    imageSize,
    hasPictureFrame,
    pictureColor,
    pictureText: {
      html: pictureText?.html5 === "\n" ? "" : pictureText?.html5,
    },
    bg: bg === "default" ? "paper" : bg,
    color: color === "default" ? "black" : color,
    isDark: brightnessFormat(bg),
    type: identifier,
    video,
    audio,
    quiz,
    mathTask: {
      articleId: id,
      assignmentType: mathTask?.assignmentType ?? "",
      assignments: assignmentsFormatted ?? [],
    },
    created: creationDate?.timestamp,
    modified: modificationDate?.timestamp,
    versionNumber,
    relations: formattedRelations,
    href: _url.replace(new RegExp(/[a-zA-Z]+\//i), ""),
  }
}

export function formatDestinationContentToRelation(
  destinationContent: DestinationContent[],
): Relation[] {
  return destinationContent.map(content => {
    switch (content._type?.identifier) {
      case "source": {
        return {
          identifier: content._type.identifier,
          resourceUrl: content._url,
          title: content.title,
          html: null,
          resource: {
            type: content.type,
            author: content.author,
            title: content.title,
            edition: content.edition,
            publisher: content.publisher,
            publishYear: content.publishYear,
            publishDate: content.publishDate
              ? new Date(content.publishDate.timestamp * 1000)
              : null,
            url: content.url,
          },
        }
      }

      case "term": {
        return {
          identifier: content._type.identifier,
          resourceUrl: content._url,
          title: content.title,
          html: null,
          resource: {
            definition: content.definition.html5,
            explanation: content.explanation.html5,
            isFlashcard: content.isFlashcard,
          },
        }
      }

      case "person": {
        return {
          identifier: content._type.identifier,
          resourceUrl: content._url,
          title: content.name,
          html: null,
          resource: {
            birthYear: content.birthYear,
            deceasedYear: content.deceasedYear,
            about: content.about.html5,
          },
          image: content.image,
          imageCaption: content.imageCaption,
        }
      }

      case "what_is": {
        return {
          identifier: content._type.identifier,
          resourceUrl: content._url,
          title: content.title,
          html: null,
          resource: {
            explanation: content.explanation?.html5,
          },
          image: content.image,
        }
      }

      default: {
        return {
          identifier: "sidenote",
          title: content?.title ?? content.id,
          html: content?.richtext?.html5 ?? "<Tomt notat>",
          resourceUrl: content._url,
          image: content.image,
          video: content.video
            ? formatVideoData(content.video)
            : null,
          ...(content.richtext === null
            ? null
            : { html: content.richtext?.html5 }),
        }
      }
    }
  })
}
