<script setup lang="ts">
import { Article } from "~/types/article"
import UIcon from "~/components/UIcon.vue"
import UBadge from "~/components/UBadge.vue"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const productContent = useProductContentStore()

const sidenotes = computed(() => {
  const whatIsSidenotes =
    props.data.relations
      ?.filter(r => r.identifier === "what_is")
      .map(r => r.title) ?? []
  return [...new Set(whatIsSidenotes)]
})

const iconName = computed(() => {
  switch (props.data.mathTask?.assignmentType) {
    case "Mengdetrening":
      return "cube-multiple"
    case "Innlæring":
    default:
      return "cube"
  }
})

const illustrationLine = `
<svg
    width="3"
    height="100%"
    viewBox="0 0 3 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
>
    <path
        d="M1.01074 1.01819L1.01075 76.6909"
        stroke="var(--theme-dark)"
        stroke-width="2"
        stroke-linecap="round"
    />
</svg>`

const isDesktop = useMediaQuery("(min-width: 1024px)")
</script>

<template>
  <div
    class="grid h-full w-full grid-cols-5 overflow-clip rounded-xl bg-platform-paper font-national2condensed text-black"
    :class="{
      'brightness-[85%]': data.restricted,
    }"
  >
    <div
      class="col-start-1 col-end-2 flex flex-col items-center bg-gradient-to-t from-[--theme-dark] to-[--theme-medium] px-3 py-4 lg:py-14"
    >
      <span class="h-[12%] lg:h-auto" v-html="illustrationLine" />
      <UIcon
        :name="iconName"
        :size="isDesktop ? 'auto' : 'xl'"
        class="py-2 lg:py-2"
      />
      <span class="h-[80%]" v-html="illustrationLine" />
    </div>
    <div
      class="col-start-2 col-end-6 bg-platform-paper p-3 pt-16 text-left lg:px-8 lg:pb-20 lg:pt-14"
    >
      <h4
        v-if="productContent.activeSubject"
        class="text-md hidden pb-4 font-national2 font-extrabold uppercase text-darker lg:block lg:pt-20 lg:text-lg"
      >
        <span>{{
          +productContent?.activeSubject?.priority +
          ". " +
          productContent?.activeSubject?.title
        }}</span>
      </h4>
      <h3
        class="truncate whitespace-pre-wrap pb-4 text-2xl font-medium lg:pb-8 lg:text-4xl xl:text-5xl"
      >
        {{ data.title ?? "Laster..." }}
      </h3>
      <dl
        v-if="!data.restricted && sidenotes"
        class="flex flex-wrap gap-1.5"
      >
        <dt v-for="sidenote in sidenotes" :key="sidenote">
          <UBadge small variant="outline">{{
            sidenote.toLowerCase()
          }}</UBadge>
        </dt>
      </dl>

      <span
        v-else
        class="font-national2 font-bold text-dark lg:text-lg"
      >
        - Tilgang krever lisens</span
      >
    </div>
  </div>
</template>
