<script setup lang="ts">
defineProps({
  title: {
    type: String,
    default: "Laster...",
  },
  restricted: {
    type: Boolean,
    default: false,
  },
})

const productContent = useProductContentStore()
</script>
<template>
  <div
    class="flex w-full h-full px-2 text-black overflow-clip rounded-xl bg-platform-paper font-national2condensed lg:px-24"
    :class="{
      'brightness-[85%]': restricted,
    }"
  >
    <div
      class="relative flex-col w-full h-full p-8 bg-platform-paper"
    >
      <h4
        class="hidden pb-4 font-extrabold uppercase text-md font-national2 text-darker lg:block lg:text-lg"
      >
        {{ productContent.activeSubject?.title }}
      </h4>
      <h3
        class="pb-6 text-2xl font-medium font-national2condensed lg:text-4xl xl:text-5xl"
      >
        {{ title }}
      </h3>
      <section
        class="flex flex-col items-center self-center justify-center w-full gap-4 p-4 bg-white rounded-lg"
      >
        <div
          class="relative flex items-center justify-start w-full h-12 p-4 rounded-xl bg-green-60/40"
        >
          <UIcon
            name="circle-filled"
            class="p-1 text-green-100 scale-75 bg-white rounded-full outline outline-2"
          />
        </div>
        <div
          class="relative flex items-center justify-start w-full h-12 p-4 rounded-xl bg-platform-paper"
        >
          <UIcon
            name="circle-hollow"
            class="text-gray-600 bg-white rounded-full"
            size="lg"
          />
        </div>
        <div
          class="relative flex items-center justify-start w-full h-12 p-4 rounded-xl bg-platform-paper"
        >
          <UIcon
            name="circle-hollow"
            class="text-gray-600 bg-white rounded-full"
            size="lg"
          />
        </div>
      </section>
    </div>
  </div>
</template>
