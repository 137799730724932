<script setup lang="ts">
import { useDeviceLimiter } from "~/composables/useDeviceLimiter"
import { useAuth } from "~/composables/useAuth"
import UIcon from "./UIcon.vue"

const { user, logout } = useAuth()
const {
  initializeDeviceLimiter,
  registerDevice,
  hasActiveDeviceConflict,
} = useDeviceLimiter()

const showDeviceConflictModal = ref(false)
const showForceLogoutModal = ref(false)
const isProcessing = ref(false)

// Track if we're handling a device conflict
const isHandlingDeviceConflict = ref(false)

const handleDeviceConflict = async (keepThisDevice: boolean) => {
  isProcessing.value = true
  try {
    if (keepThisDevice && user.value?.uid) {
      await registerDevice(user.value.uid)
      isHandlingDeviceConflict.value = false
    } else {
      await logout()
    }
  } finally {
    setTimeout(() => {
      isProcessing.value = false
      showDeviceConflictModal.value = false
    }, 3000)
  }
}

// Initialize device limiting when user logs in
watch(
  () => user.value?.uid,
  async newUserId => {
    if (!newUserId) return
    isHandlingDeviceConflict.value = true
    await initializeDeviceLimiter(newUserId)

    if (hasActiveDeviceConflict.value) {
      showDeviceConflictModal.value = true
    } else {
      isHandlingDeviceConflict.value = false
    }
  },
  { immediate: true },
)

// Handle device conflicts detected during polling
watch(
  () => hasActiveDeviceConflict.value,
  async hasConflict => {
    if (
      hasConflict &&
      user.value?.uid &&
      !isHandlingDeviceConflict.value
    ) {
      showForceLogoutModal.value = true
      await new Promise(resolve => setTimeout(resolve, 10000))
      await logout()
    }
  },
)
</script>

<template>
  <UModal
    v-model="showDeviceConflictModal"
    :header="false"
    class="!pointer-events-none"
    :close-on-click-outside="false"
  >
    <div class="py-5 px-7">
      <p
        class="mb-4 text-purple-darker text-desktop-article-h2 font-national2condensed"
      >
        En annen enhet er innlogget
      </p>

      <p class="mb-8 text-xl text-purple-darker font-national2">
        Du kan bare være aktiv med én enhet om gangen på Kunne. Ved å
        fortsette her, blir andre enheter logget ut.
      </p>
      <div class="flex flex-col gap-3 md:flex-row">
        <UButtonNew
          data-pendo="device-limiter-use-this-device"
          variant="outline"
          bold
          :disabled="isProcessing"
          @click="handleDeviceConflict(true)"
        >
          <template v-if="isProcessing">
            <UIcon name="spinner" />
          </template>
          <template v-else>Bruk denne enheten</template>
        </UButtonNew>
        <UButtonNew
          data-pendo="device-limiter-cancel"
          variant="outline"
          bold
          :disabled="isProcessing"
          @click="handleDeviceConflict(false)"
        >
          Avbryt
        </UButtonNew>
      </div>
    </div>
  </UModal>

  <UModal
    v-model="showForceLogoutModal"
    :header="false"
    :close-on-click-outside="false"
  >
    <div class="py-5 px-7">
      <p
        class="mb-4 text-purple-darker text-desktop-article-h2 font-national2condensed"
      >
        En ny enhet har logget inn med brukeren din
      </p>

      <p class="mb-8 text-xl text-purple-darker font-national2">
        Du kan bare være innlogget med én enhet om gangen på Kunne.
      </p>
      <div class="flex items-center gap-2">
        <UIcon name="spinner" />
        <span class="text-purple-darker"
          >Du blir nå logget ut...</span
        >
      </div>
    </div>
  </UModal>
</template>
