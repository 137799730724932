<script setup>
const personalization = usePersonalizationStore()
const route = useRoute("article")
</script>

<template>
  <div
    v-if="personalization.lastReadArticle"
    class="pt-[40px] lg:pt-[65px]"
  >
    <router-link
      v-if="personalization.lastReadArticle"
      :to="`/produkter/${route.params.product}/${
        route.params.variant
      }${personalization.lastReadArticle.href.replace(
        /^\/[^\/]+\//,
        '/',
      )}`"
    >
      <div
        class="mt-[-.4em] w-full rounded-xl bg-[--theme-dark] p-6 pb-8 font-extrabold text-[--theme-lightest] shadow-[-8px_12px_8px_-6px_grey,-12px_14px_0px_-1px_var(--theme-lightest)] duration-200 hover:translate-x-[5px] hover:translate-y-[-5px] hover:shadow-[-10px_14px_8px_-6px_grey,-17px_19px_0px_-1px_var(--theme-lightest)]"
      >
        <div
          class="flex items-center justify-start text-[--theme-lightest]"
        >
          <p class="pb-6 font-national2 text-2xl">Sist lest</p>
        </div>
        <div class="flex flex-col gap-4">
          <p
            class="max-w-[200px] truncate font-national2 text-lg uppercase leading-5 md:max-w-[500px]"
          >
            {{ personalization.lastReadArticle.priority }}
            {{ personalization.lastReadArticle.subjectTitle }}
          </p>
          <p
            class="line-clamp-2 max-w-[200px] overflow-hidden font-national2condensed text-5xl font-medium md:max-w-[800px]"
          >
            {{ personalization.lastReadArticle.articleTitle }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>
