import gql from "graphql-tag"

export default gql`
  query ($urlAlias: String) {
    _repository {
      location(urlAlias: $urlAlias) {
        id
        priority
        urlAliases(custom: true) {
          path
        }
        content {
          ... on PageContent {
            layout
            title
            subtitle
            metaTitle
            metaDescription
            metaImage {
              alternativeText
              id
              text
              variation(identifier: fullwidth) {
                uri
              }
            }
            _location {
              children(sortBy: _priority) {
                edges {
                  node {
                    id
                    priority
                    content {
                      ... on PageSectionContent {
                        title
                        richtext {
                          html5
                        }
                        image {
                          alternativeText
                          variation(identifier: fullwidth) {
                            uri
                          }
                        }
                        video {
                          id
                          title
                          image {
                            variation(identifier: reference) {
                              uri
                            }
                            alternativeText
                          }
                          previewTitle
                          publishedBy
                          published
                          timestamps {
                            description
                            time
                          }
                          metadata
                          embedCode
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
