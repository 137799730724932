<script setup lang="ts">
import { formatCurrency } from "~/utilities/format"
import * as Sentry from "@sentry/vue"
import { ProductInformation } from "~/models/Content/ContentProducts"
import { EcommerceProductPrice } from "~/types/ecommerce/product"
import UContactCustomerService from "~/components/CustomerService/UContactCustomerService.vue"

const props = defineProps({
  selectedProduct: {
    type: Object as PropType<ProductInformation>,
    required: true,
  },
  showPayment: {
    type: Boolean,
    required: true,
    default: true,
  },
  price: {
    type: Object as PropType<EcommerceProductPrice>,
    required: false,
    default: undefined,
  },
})

const route = useRoute<"productCheckout" | "login2">() // TODO: Update login route name

const ecommerceData = computed(() => props.selectedProduct.eCommerce)
const productIsFree = computed(() => props.price?.actual === 0)

watchEffect(() => {
  if (!props.selectedProduct.ean || !ecommerceData.value) {
    Sentry.captureException(
      `There is a mismatch in EAN value from e-commerce and CMS for [${props.selectedProduct?.name}, ${props.selectedProduct?.shortVariantLabel}], e-commerce=[${ecommerceData.value?.ean}], cms=[${props.selectedProduct?.ean}]`,
    )
  }
})
</script>

<template>
  <div
    v-if="
      (showPayment && ecommerceData && selectedProduct) ||
      (!showPayment && selectedProduct.ean)
    "
    class="flex max-w-[720px] items-center justify-between gap-14 rounded-2xl bg-white p-8 font-national2 text-xl shadow-xl <lg:flex-col"
  >
    <div
      class="flex aspect-square h-[232px] items-center justify-center rounded-md bg-[--theme-lightest]"
    >
      <img
        :alt="
          ecommerceData?.imageDescription ||
          'Mangler bildebeskrivelse'
        "
        :src="ecommerceData?.imageUrl"
        class="max-h-[80%]"
      />
    </div>
    <div class="flex flex-col gap-2">
      <p class="mb-4">
        <b>{{ selectedProduct?.curriculumFor }}</b>
      </p>
      <h3
        class="text- font-national2compressed text-4xl text-[--theme-darkest]"
      >
        <b>Kunne {{ selectedProduct?.title }}</b>
      </h3>
      <p v-if="ecommerceData?.description">
        {{ ecommerceData.description }}
      </p>
      <div class="flex flex-col justify-between">
        <br />
        <div
          v-if="showPayment && !productIsFree"
          class="flex flex-col justify-between"
        >
          <div v-if="price?.actual">
            <UPrice :price="price" :view-type="'list'" />
            <p
              class="mt-3 text-mobile-article-p1 leading-[26px] lg:text-marketing-desktop-p1-p1-small lg:leading-[30px]"
            >
              <i>Avsluttes automatisk etter semesterslutt</i>
            </p>
          </div>
          <p v-else>
            Ops! Fant ikke pris for dette produktet, prøv igjen
            senere.
          </p>
          <br />
        </div>
        <div>
          <UButtonNew
            v-show="!productIsFree && showPayment"
            type="submit"
            pill
            theme="cta"
            variant="filled"
            >Betal</UButtonNew
          >
          <UButtonNew
            v-show="productIsFree || !showPayment"
            :to="`/produkter/${route.params.product}/${selectedProduct?.variantKey}`"
            pill
            theme="cta"
            variant="filled"
            data-pendo="checkout-prov-gratis"
            >Prøv gratis</UButtonNew
          >
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="flex flex-col gap-6 bg-white shadow-md rounded-2xl font-national2 sm:px-16 sm:py-16"
  >
    <p>
      Ops, noe gikk galt, og vi greide ikke hente informasjon om
      produktet.
    </p>
    <p>
      Prøv igjen senere.
      <UContactCustomerService /> om feilen vedvarer.
    </p>
  </div>
</template>
