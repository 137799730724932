import type { ContentProductPages } from "~/models/Content/ContentProductPages"
import { formatForURL } from "~/utilities/composables"
import contentApi from "~/api/getContent"
import { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"
import { ContentProductPageUsp } from "~/models/Content/ContentProductPageUsp"
import { ContentType } from "~/models/Content/ContentType"
import { ContentProductPageMediaTypes } from "~/models/Content/ContentProductPageMediaTypes"

const PRODUCTS_PAGES_LOCATION_ID = import.meta.env
  .VITE_CMS_PRODUCTS_PAGES_LOCATION_ID as string | "0"

const contentData = contentApi().getContent

export type ProductPageList = Record<string, ContentProductPages>

export async function getProductPageList(): Promise<ProductPageList | null> {
  const result = await contentData<ContentProductPages>({
    contentTypeCriterion: ["product_page"],
    parentLocationIdCriterion: [parseInt(PRODUCTS_PAGES_LOCATION_ID)],
  })

  for (const p of result) {
    const productPageChildren = await contentData<
      ContentProductPageBlocks | ContentProductPageMediaTypes
    >({
      contentTypeCriterion: [
        "product_page_block",
        ContentType.ProductPageMediaTypes,
      ],
      parentLocationIdCriterion: [p.locationId],
      sortField: "priority",
    })

    const productPageUsps = await contentData<ContentProductPageUsp>({
      contentTypeCriterion: [ContentType.ProductPageUSP],
      parentLocationIdCriterion: [p.locationId],
      sortField: "priority",
    })

    if (productPageChildren && productPageChildren.length > 0) {
      p.blocks = productPageChildren
    }

    if (productPageUsps && productPageUsps.length > 0) {
      p.uspList = productPageUsps[0].uspList
    }
  }

  return result.reduce<ProductPageList>((acc, page) => {
    const key = formatForURL(
      page.title.replaceAll(" ", "-").toLowerCase(),
    )
    acc[key] = page
    return acc
  }, {})
}

export function isProductPageBlock(
  object: unknown,
): object is ContentProductPageBlocks {
  return (
    typeof object === "object" &&
    !!object &&
    "body" in object &&
    typeof object.body === "string" &&
    "header" in object &&
    typeof object.header === "string" &&
    "blockOrientation" in object &&
    typeof object.blockOrientation === "string"
  )
}

export function isProductPageMediaTypes(
  object: unknown,
): object is ContentProductPageMediaTypes {
  return (
    typeof object === "object" &&
    !!object &&
    "productMediaTypes" in object &&
    Array.isArray(object.productMediaTypes)
  )
}
