import {
  article__template,
  article__image_size,
  article__bg,
  article_color,
} from "./article-selections"
import { subject_page__template } from "./subject-page-selections"
import {
  subject__subject_page_title_position,
  subject__chapter_type,
} from "./subject-selections"
import { sidenote__type } from "./sidenote-selection"
import { video__title_color } from "./video.selections"

export interface SelectionMapping {
  [key: string]: {
    [key: string]: Record<string, string>
  }
}

export const selectionTypes: SelectionMapping = {
  article: {
    template: article__template,
    image_size: article__image_size,
    bg: article__bg,
    color: article_color,
  },
  subject_page: {
    template: subject_page__template,
  },
  subject: {
    subject_page_title_position: subject__subject_page_title_position,
    chapter_type: subject__chapter_type,
  },
  sidenote: {
    type: sidenote__type,
  },
  video: {
    title_color: video__title_color,
  },
}
