<script setup lang="ts">
import type { NavProps } from "~/types/nav.d"
import type { Folder } from "~/types/folder"

import { useAuth } from "~/composables/useAuth"
import { identity } from "lodash-es"
import { useLoginPageUrl } from "~/composables/useLoginPageUrl"
import { ProductInformation } from "~/models/Content/ContentProducts"

/**
 * @see https://github.com/vuejs/core/issues/4294#issuecomment-896082769
 * import type { NavProps } from '~/types/nav.d'
import { useRoute } from '../../../../typed-router';
 * withDefaults(defineProps<NavProps>(), {})
 */

defineProps({
  folders: {
    type: Array as PropType<Folder[]>,
    default: () => [],
  },
  product: {
    type: Object as PropType<ProductInformation>,
    required: true,
    default: () => ({}),
  },
  data: {
    type: Array as PropType<NavProps["data"]>,
    required: true,
    default: () => [],
  },
  listType: {
    type: String as PropType<NavProps["listType"]>,
    default: "ul",
  },
  pageType: {
    type: String as PropType<string>,
    default: "subject",
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const products = useProductVariantsStore()

const route = useRoute<"subject">()
const { isAuthorized } = useAuth()
const loginPageUrl = useLoginPageUrl()

const productParam = computed(() => route.params.product)
const variantParam = computed(() => route.params.variant)

const showSearch = ref(false)
</script>

<template>
  <nav
    id="navigation"
    class="navigation sticky left-4 top-0 h-full w-screen bg-[--theme-darkest] text-white backdrop-blur-sm sm:min-w-[14em] md:max-w-[24em] <md:flex <md:items-center <md:justify-center <md:pb-5"
  >
    <div class="flex flex-col items-center justify-center">
      <div v-if="!showSearch" class="flex h-full w-full">
        <ULogo
          :product="products.activeProduct"
          aria-label="Gå tilbake kapitteloversikten"
          full-width
          small
          class="pt-12 <md:hidden"
          :dark="false"
        />
        <div
          id="mobile-nav"
          class="flex w-full items-center justify-evenly gap-9 self-center rounded-b-lg pb-6 pt-4 sm:gap-10 md:justify-end md:gap-0 md:p-0"
        >
          <u-button
            v-if="!showSearch"
            class="md:!hidden"
            mobile
            :to="`/produkter/${productParam}/${variantParam}`"
          >
            <template #prepend>
              <u-icon name="home" size="sm" />
            </template>
            Forside
          </u-button>

          <u-button
            v-if="!isAuthorized"
            mobile
            class="md:!hidden"
            :to="loginPageUrl"
          >
            <template #prepend>
              <u-icon name="user" size="sm" />
            </template>
            Logg&nbsp;inn
          </u-button>

          <div v-else>
            <u-button
              v-if="!showSearch"
              id="min-side-small"
              mobile
              data-pendo="min-side-mobile"
              class="md:!hidden"
              :to="
                `/produkter/${[
                  route.params.product,
                  route.params.variant,
                  route.params.subject,
                ]
                  .filter(identity)
                  .filter(identity)
                  .join('/')}` + '/min-side'
              "
            >
              <template #prepend>
                <u-icon name="user" size="sm" />
              </template>
              Min&nbsp;side
            </u-button>
          </div>
          <u-button
            v-if="!showSearch"
            mobile
            class="md:!hidden"
            :to="
              `/produkter/${[
                route.params.product,
                route.params.variant,
              ]
                .filter(identity)
                .filter(identity)
                .join('/')}` + '/sok'
            "
          >
            <template #prepend>
              <u-icon name="search" size="sm" />
            </template>
            Søk
          </u-button>
        </div>
      </div>
      <div
        class="flex w-full items-center justify-center py-12"
        :class="{ '<md:hidden': !showSearch }"
      >
        <button
          v-if="showSearch"
          class="mr-2 h-12 w-12 flex-none rounded-full border-white p-2 md:hidden"
          @click="showSearch = false"
        >
          <u-icon
            class="ml-[3px] stroke-white text-white"
            name="close"
            size="sm"
          />
        </button>
        <u-search-bar class="w-full" />
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <u-nav-list
        v-if="data.length"
        :data="data"
        :page-type="pageType"
        :folders="folders"
        class="h-fit w-full pb-24 md:h-[calc(85svh-6rem)] <md:hidden"
        :licence="licence"
      >
        <template #list-top>
          <slot name="list-top" />
        </template>
      </u-nav-list>
      <u-nav-list-skeleton v-else class="hidden md:block" />
    </transition>
  </nav>
</template>

<!-- @apply mx-[-1.25rem] md:px-[1.25rem]; -->
<style scoped>
.min-side {
  @apply border-portal-indigo-900 text-white;
}
.navigation {
  @apply z-30;

  .top {
    @apply <md:grid <md:grid-cols-[max-content,1fr];
  }
}
.bottom {
  @apply absolute bottom-1 hidden py-5;
  @apply md:block;
}

#mobile-nav {
  a,
  button {
    @apply p-4;
  }
}
</style>
