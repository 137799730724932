<template>
  <transition-group
    tag="ul"
    :css="false"
    class="nav-list no-scrollbar"
  >
    <li
      v-for="(_, key) in Array.from({ length: 14 })"
      :id="`nav-skeleton-element-${key}`"
      :key="key"
      class="w-full"
    >
      <u-skeleton class="skeleton" />
    </li>
  </transition-group>
</template>

<style scoped lang="postcss">
.nav-list {
  @apply list-inside overflow-scroll text-white;

  & > li {
    @apply py-3;

    & > .skeleton {
      @apply rounded-lg bg-[--theme-darkest];
    }
  }
}
</style>
