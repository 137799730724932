import type { Licence } from "~/types/licence.d"
import * as Sentry from "@sentry/vue"
import axios from "axios"

export async function getLicences(): Promise<Licence[]> {
  try {
    const data = await api.external.get(
      // eslint-disable-next-line vue/max-len
      "/rest/frontend/licenses/products?preferredLangague=nb&site=KUNNE",
    )
    return data.data
  } catch (error) {
    const isUnauthorized =
      axios.isAxiosError(error) && error.response?.status === 401

    if (!isUnauthorized) {
      Sentry.captureException(error)
    }

    return []
  }
}
