export enum ContentType {
  Product = "product",
  Article = "article",
  ProductPage = "product_page",
  ProductPageBlock = "product_page_block",
  ProductPageUSP = "product_page_usp",
  ProductPageMediaTypes = "product_page_media_types",
  ProductPages = "product_pages",
  Image = "image",
  Svg = "svg",
  Unknown = "unknown",
}
