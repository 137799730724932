import type { IbexaVideoContentType } from "~/types/ibexa"
import type { Video } from "~/types/media"

export function formatVideoData(i: IbexaVideoContentType): Video {
  return {
    id: i.id,
    title: i.title,
    previewTitle: i.previewTitle,
    img: {
      alt: i.image?.alternativeText,
      src: i.image?.variation.uri,
    },
    published: i.published,
    publishedBy: i.publishedBy,
    timestamps: i.timestamps.map((stamp, key) => {
      return {
        id: i.id + "-video-timestamp-" + key,
        ...stamp,
      }
    }),
    embedCode: i.embedCode,
    transcription: {
      html: i.transcription ? i.transcription.html5 : "",
    },
    metadata: JSON.parse(
      i.metadata.replaceAll(
        "media.lokus.no",
        "media.aschehoug.cloud",
      ),
    ),
    captions: i.captions,
    _type: i._type,
  }
}
export default (articles: IbexaVideoContentType[]): any => {
  if (!articles?.length) return []

  return articles
    .map((i: IbexaVideoContentType) => {
      if (i._type.identifier === "video") {
        return formatVideoData(i)
      }
    })
    .filter(Boolean)
}
