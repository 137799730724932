<script setup lang="ts">
const productContent = useProductContentStore()

const personalization = usePersonalizationStore()

const router = useRouter()

const handleNextArticleClick = async () => {
  if (
    productContent.activeArticle?.id &&
    productContent.nextArticle?.frontendHref
  ) {
    personalization.addArticleToReadHistory(
      productContent.activeArticle.id,
      true,
    )
    await router.push(productContent.nextArticle.frontendHref)
  }
}
</script>

<template>
  <div class="flex items-center justify-end md:justify-start">
    <UButtonNew
      v-if="productContent.nextArticle?.id"
      pill
      theme="light"
      class="mr-6"
      @click="handleNextArticleClick"
    >
      <template #prepend>
        <u-icon name="arrow-down" />
      </template>
      Neste
    </UButtonNew>
    <span
      class="hidden grow truncate whitespace-nowrap text-button-medium-button-text-regular md:block"
    >
      {{ productContent.nextArticle?.title }}</span
    >
  </div>
</template>
