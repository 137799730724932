import {
  ProductsMap,
  ProductVariants,
} from "~/models/Content/ContentProducts"
import * as Sentry from "@sentry/vue"
import { EcommerceProduct, ProductStatus } from "~/types/productData"
import { ProductPageList } from "~/api/getProductPageList"
import {
  formatList,
  parseInstitutionNameAndCode,
} from "~/utilities/format"
import { formatForURL } from "~/utilities/composables"

export const getGroupedProductVariants = (
  productVariantsData: ProductVariants[],
  ecommerceData: EcommerceProduct[],
  marketingData: ProductPageList | null,
): ProductsMap | undefined => {
  const productVariants: ProductsMap = {}
  for (const product of productVariantsData) {
    const variantTitle = product.ibexaTitle

    const words = variantTitle.split(" ")

    if (words.length < 2) {
      Sentry.captureException(
        "Wrong structure in product variant title, expected title to be at least two words on the form [productTitle + productVariant].",
      )

      console.error(
        "Wrong structure in product variant title, expected title to be at least two words on the form [productTitle + productVariant].",
      )
      console.error("Product Title Found: ", product.ibexaTitle)
      continue
    }

    // The variant (ex. "UiO" or "NTNU") is the last word in the title
    const variant = words[words.length - 1]
    const productTitle = variantTitle.replace(` ${variant}`, "")

    const productKey = buildProductKey(product.ibexaTitle)

    const productIsFree = computed(
      () =>
        ecommerceData.filter(p => p.ean === product.ean)[0]?.price ===
        0,
    )

    const parsedPensumForField = parseInstitutionNameAndCode(
      product.pensumFor,
    )

    // institute is for examle "Universitetet i Oslo (UiO)" we want to extract the short name
    // and will return "UiO"
    const combinedVariantShortNames = formatList(
      product.institute.map(i => {
        const startIndex = i.indexOf("(")
        const endIndex = i.indexOf(")")
        if (startIndex !== -1 && endIndex !== -1) {
          return i.substring(startIndex + 1, endIndex)
        }
        return i
      }),
    )

    if (!productVariants[productKey]) {
      productVariants[productKey] = {
        productTitle,
        productTitleHtml: product.titleHtml,
        urlFormatProductTitle: formatForURL(productTitle),
        hasAtLeastOneReleasedProduct: product.isReleased,
        productGroupStatus:
          product.status[0] !== undefined && product.ean
            ? [
                {
                  ean: product.ean,
                  status: product.status[0] as ProductStatus,
                },
              ]
            : undefined,
        theme: marketingData?.[productKey]?.theme,
        header: marketingData?.[productKey]?.header?.html5,
        quote: marketingData?.[productKey]?.quote,
        groupSalesPitch:
          marketingData?.[productKey]?.groupSalesPitch?.html5,
        productGroupStatusDetails:
          marketingData?.[productKey]?.productGroupStatusDetails,
        productCurriculumFor:
          parsedPensumForField.shortNamesAndCourseCodes,
        vanitySentence: parsedPensumForField.vanitySentence,
        variants: [
          {
            name: variantTitle,
            shortTitle: product.shortTitle,
            titleHtml: product.titleHtml,
            title: product.title,
            ean: product.ean,
            curriculumFor: combinedVariantShortNames,
            isReleased: product.isReleased,
            status: product.status,
            isFree: productIsFree.value,
            variantKey: variant.toLowerCase(),
            shortVariantLabel: variant,
            locationId: product._location.id,
            pathString: product._location.pathString,
          },
        ],
      }
    } else {
      productVariants[productKey].productCurriculumFor = [
        ...productVariants[productKey].productCurriculumFor,
        ...parsedPensumForField.shortNamesAndCourseCodes,
      ]
      if (product.ean && product.status) {
        productVariants[productKey].productGroupStatus = [
          ...(productVariants[productKey].productGroupStatus || []),
          {
            ean: product.ean,
            status: product.status[0] as ProductStatus,
          },
        ]
      }
      productVariants[productKey].vanitySentence =
        parsedPensumForField.vanitySentence
      productVariants[productKey].variants.push({
        name: variantTitle,
        shortTitle: product.shortTitle,
        titleHtml: product.titleHtml,
        title: product.title,
        ean: product.ean,
        curriculumFor: combinedVariantShortNames,
        isReleased: product.isReleased,
        status: product.status,
        isFree: productIsFree.value,
        variantKey: variant.toLowerCase(),
        shortVariantLabel: variant,
        locationId: product._location.id,
        pathString: product._location.pathString,
      })
    }
    if (
      productVariants[productKey] &&
      !productVariants[productKey].hasAtLeastOneReleasedProduct &&
      product.isReleased
    ) {
      productVariants[productKey].hasAtLeastOneReleasedProduct = true
    }

    if (
      product.titleHtml &&
      !productVariants[productKey].productTitleHtml
    ) {
      productVariants[productKey].productTitleHtml = product.titleHtml
    }
  }

  return Object.keys(productVariants).length > 0
    ? productVariants
    : undefined
}

function buildProductKey(ibexaTitle: string) {
  // We only want the part after '/' and before the last '-' in the URL, ex. '/kunne/exphil-uio' -> 'exphil'

  const segments = ibexaTitle.split(" ")
  return formatForURL(
    segments.slice(0, segments.length - 1).join("-"),
  )
}
