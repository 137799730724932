import * as Sentry from "@sentry/vue"
import { api } from "~/axios"

interface KeyVal {
  userId: string
  key: string
  value: unknown
}

export async function set(
  key: string,
  value: unknown,
  userId?: string,
): Promise<void> {
  if (!userId) return
  try {
    const data: KeyVal = {
      userId: userId,
      key: key,
      value: JSON.stringify(value),
    }
    await api.external.put(`/rest/frontend/metadata/${key}`, data)
  } catch (error) {
    Sentry.captureException(error)
  }
}
