import { Relation } from "~/types/article"

export function sanitizeMathMl(mathML: string, parser: DOMParser) {
  const parsed = parser.parseFromString(mathML, "text/html")

  // Unwrap all mstyle tags
  parsed.querySelectorAll("mstyle").forEach(el => {
    el.replaceWith(...Array.from(el.children))
  })

  // Ensure that mtd elements (cells) used in tables and matrices are left-aligned
  parsed.querySelectorAll("mtd").forEach(mtd => {
    mtd.style.textAlign = "left"
  })

  // Set font-family for matrices
  parsed.querySelectorAll("mo").forEach(mo => {
    if (mo.textContent === "[" || mo.textContent === "]") {
      mo.style.fontFamily = "math"
    }
  })

  return parsed
}

/**
 * Creates a function that can be used to parse HTML strings with links to prepare them for displaying sidenotes.
 *
 * When calling the createHtmlFormatter function, a DOM parser is initialized, and a function is returned which can
 * be called to parse HTML strings in order to format it correctly to display sidenotes. This formatting function
 * which is returned loops over all "a" tags in the HTML string, and mutates the "a" tags inside it: Classes and
 * attributes are added based on which kind of identifier the matching relation has, and the href is updated to
 * contain a hash "#" as a prefix.
 *
 * @param {Relation[]} formattedRelations - A list of relations to the given content being parsed, for instance an
 * article. Elements in the list contains attributes like `resourceUrl`, `identifier` and the rest of the content
 * used in sidenotes.
 * @param {DOMParser} parser - The DOM parser to use for parsing the HTML string.
 *
 * @returns {function(html: string) => string} A function which takes the html string to format, and returns the parsed HTML.
 *
 * @example
 * // Example with a string that contains an "a" tag with a href, which is parsed to contain a hash and classes.
 * // Create the formatter, which initializes the DOM parser, and call the function with the HTML string to parse:
 *
 * const formatHtml = createHtmlFormatter(formattedRelations);
 * formatHtml("<a href='/kunne/sidenotater/exphil-uio/begrepsforklaring/indre-og-ytre-virkelighet'>[1]</a>");
 *
 * // Output:
 * "<a href='#/kunne/sidenotater/exphil-uio/begrepsforklaring/indre-og-ytre-virkelighet' class='text-link text-link--sidenote text-link--source' role='button'><sup>[1]</sup></a>"
 */
export function createHtmlFormatter(
  formattedRelations: Relation[],
  parser = new DOMParser(),
) {
  const sourceUrls: string[] = []

  return (html: string) => {
    const parsed = sanitizeMathMl(html, parser)

    parsed.querySelectorAll("a").forEach(el => {
      const [isRef = false, appendText] =
        el.textContent?.trim().match(/^\[(?:\*|\d+)(.+)?]$/) ?? []

      const relation = formattedRelations.find(
        ({ resourceUrl }) => resourceUrl === el.getAttribute("href"),
      )

      if (relation) {
        el.href = `#${relation.resourceUrl}`
        el.classList.add("text-link", "text-link--sidenote")
        el.setAttribute("role", "button")

        if (isRef) {
          relation.appendText = appendText
          relation.identifier = "source"
        }

        switch (relation.identifier) {
          case "source": {
            let index = sourceUrls.indexOf(relation.resourceUrl) + 1
            if (!index) {
              sourceUrls.push(relation.resourceUrl)
              index = sourceUrls.length
            }

            relation.title = `${index}.`
            el.innerHTML = `<sup>[${index}]</sup>`
            el.classList.add("text-link--source")

            if (relation.appendText && relation.html) {
              const { html, appendText } = relation
              const index = html?.lastIndexOf(".")
              if (index >= 0) {
                relation.html = `${html.substring(
                  0,
                  index,
                )}, ${appendText}.${html.substring(index + 1)}`
              }
            }
            break
          }

          case "what_is": {
            const parsedSidenote = sanitizeMathMl(
              relation.resource.explanation,
              parser,
            )
            relation.resource.explanation =
              parsedSidenote.body.innerHTML

            break
          }
          case "explainer":
          case "sidenote": {
            relation.identifier = "explainer"
            el.classList.add("text-link--explainer")
            break
          }
        }
      }
    })

    return parsed.body.innerHTML
  }
}
