import { ProductGroup } from "~/models/Content/ContentProducts"
import {
  EcommercePriceVariantIdentifier,
  EcommercePriceVariants,
  EcommerceProduct,
  EcommerceProductPrice,
} from "~/types/ecommerce/product"

export const getProductVariantPrice = (product?: ProductGroup) => {
  if (product) {
    const price = product?.variants[0]?.eCommerce?.price
    return typeof price === "number" && price >= 0 ? price : undefined
  }
  return undefined
}

export const getProductVariantPriceLabel = (
  product?: ProductGroup,
) => {
  const price = getProductVariantPrice(product)
  if (price !== undefined) {
    if (typeof price === "number" && price > 0) {
      return " Pris "
    }
    return " Gratis "
  }
  return ""
}

const KEY_MAPPING = {
  default: "default",
  discount: "discount",
  "consumer-discount": "consumerDiscount",
  "consumer-price": "consumerPrice",
} as const satisfies Record<
  EcommercePriceVariantIdentifier,
  keyof EcommercePriceVariants
>

export function getEcommerceProductPrice(
  catalogueData: EcommerceProduct[] | undefined,
  productEan: string | undefined,
): EcommerceProductPrice | undefined {
  if (!catalogueData || catalogueData.length === 0 || !productEan)
    return undefined

  const variants = catalogueData
    .flatMap(it => it.variants)
    .find(it => it.ean === productEan)
    ?.priceVariants?.reduce<EcommercePriceVariants>(
      (variants, currentVariant) => {
        const variantKey = KEY_MAPPING[currentVariant.identifier]
        if (variantKey && typeof currentVariant.price === "number") {
          variants[variantKey] = currentVariant.price
        }
        return variants
      },
      {},
    )

  if (!variants) return undefined

  const discounted = variants.consumerDiscount ?? variants.discount
  const original = variants.consumerPrice ?? variants.default

  return {
    original,
    actual: discounted ?? original,
    discounted,
    discount:
      discounted && original
        ? {
            value: original - discounted,
            percentage: Math.floor(
              ((original - discounted) / original) * 100,
            ),
          }
        : undefined,
  }
}
