import gql from "graphql-tag"

export default gql`
  query ($urlAlias: String) {
    _repository {
      location(urlAlias: $urlAlias) {
        id
        content {
          ... on MarketingArticleContent {
            id
            _content {
              id
              locations {
                children(sortBy: _priority) {
                  edges {
                    node {
                      content {
                        ... on MarketingArticleSectionContent {
                          id
                          sectionBody {
                            html5
                          }
                          sectionBodyIsAQuote
                          sectionTitle
                          buttonText
                          buttonUrl
                          image {
                            variation(identifier: fullwidth) {
                              uri
                              width
                            }
                            alternativeText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            title
            subtitle {
              html5
            }
            tags
          }
        }
      }
    }
  }
`
