<script setup lang="ts">
const applicationMeta = useApplicationMetaStore()
applicationMeta.setNewestVersion()

const newVersionExists = computed(
  () =>
    applicationMeta.currentVersion !== applicationMeta.newestVersion,
)

async function handleReload() {
  location.reload()
}
</script>

<template>
  <div>
    <p v-if="applicationMeta.currentVersion" class="mt-5">
      Versjon: {{ applicationMeta.currentVersion }}
    </p>
    <UButtonNew
      v-if="newVersionExists"
      class="mt-5"
      size="sm"
      theme="white"
      @click="handleReload"
    >
      <template #prepend>
        <u-icon name="download" size="sm" />
      </template>
      Hent nyeste versjon
    </UButtonNew>
  </div>
</template>

<style scoped lang="postcss"></style>
