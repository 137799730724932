<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router"

/**
 * u-button
 *
 * Some notes on this component:
 * - We don't set [disabled] attribute on the element, because we still want it
 *   to be accessible. This also means we have to handle "disabled" clicks
 *   manually (@click).
 *
 * - We spread out attributes to avoid setting element type specific
 *   attributes like [href] on button.
 *
 * - We explicitly define the attributes [to] and [href] so we can render the
 *   correct element, other attributes not defined like [type] and [target] are
 *   passed down automaticlly as usual.
 */

//  TODO: remove

const props = defineProps({
  to: [String, Object] as PropType<string | RouteLocationRaw>,
  href: String,
  disabled: Boolean,
  pill: Boolean,
  mobile: Boolean,
  cta: Boolean,
  stacked: Boolean,
  active: Boolean,
  theme: String as PropType<
    | "red"
    | "green"
    | "blue"
    | "portal"
    | "skin"
    | "white"
    | "skin-medium"
  >,

  size: {
    type: String as PropType<
      "sm" /*36 AA*/ | "md" /*48 AAA*/ | "lg" /*60 AAA*/
    >,
    default: "md",
  },

  variant: {
    type: String as PropType<"filled" | "outline" | "quite">,
    default: "outline",
  },
})

const emit = defineEmits(["click", "update:active"])

const component = computed(() => {
  if (props.to != null) return "router-link"
  if (props.href != null) return "a"
  return "button"
})

const attributes = computed(() => {
  switch (component.value) {
    case "router-link": {
      return {
        to: props.to,
        activeClass: "u-button--active",
      }
    }

    case "a": {
      return {
        href: props.href,
      }
    }
  }
})

function onClick(event: Event) {
  if (props.disabled) return event.preventDefault()

  emit("click", event)
  emit("update:active", !props.active)
}
</script>

<template>
  <component
    :is="component"
    class="u-button"
    :aria-disabled="disabled ? 'true' : null"
    :class="[
      `u-button--${props.size}`,
      `u-button--${props.variant}`,
      {
        'u-button--pill': props.pill,
        'u-button--cta': props.cta,
        'u-button--mobile': props.mobile,
        'u-button--stacked': props.stacked,
        'u-button--active': props.active,
        [`u-button--theme-${props.theme}`]: props.theme,
      },
    ]"
    :="attributes"
    @click="onClick"
  >
    <slot name="prepend" />
    <span v-if="$slots.default" class="u-button__text">
      <slot />
    </span>
    <slot name="append" />
  </component>
</template>

<style scoped>
.u-button {
  --button-padding-block: 12px;
  --button-padding-inline: 12px;
  --button-border-width: 2px;

  --button-color: var(--color-main);

  --color-main: currentColor;
  --color-hover: var(--color-red-60);
  --color-pressed: var(--color-red-60);
  --color-focus: var(--color-red-60);

  display: inline-flex;
  overflow: clip;
  align-items: center;
  font-family: National2, sans-serif;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 400;
  border: var(--button-border-width) solid transparent;
  border-radius: 6px;
  gap: var(--button-padding-inline);
  color: var(--color-main);
  padding: calc(
      var(--button-padding-block) - var(--button-border-width)
    )
    calc(var(--button-padding-inline) - var(--button-border-width));

  &:hover:not([aria-disabled]) {
    --button-color: var(--color-hover);
  }

  &:active:not([aria-disabled]) {
    --button-color: var(--color-pressed);
    --button-border-width: 2px;
  }

  &:focus-visible {
    --button-color: var(--color-focus);
    background-color: var(--button-color);

    /** While color-contrast is not supported, we need to force the color */
    color: var(--color-portal-indigo-900) !important;
  }

  &[aria-disabled] {
    opacity: 0.5;
  }

  &.u-button--sm {
    --button-padding-block: 6px;
    font-size: 1.125rem;
    line-height: calc(1 + 1 / 3);
  }

  &.u-button--lg {
    @media (min-width: 744px) {
      --button-padding-inline: 16px;
      font-size: 1.75rem;
      line-height: 2.25rem;
    }
  }

  &.u-button--theme-red {
    --color-main: var(--color-red-60);
    --color-hover: var(--color-red-80);
    --color-pressed: var(--color-red-100);
  }

  &.u-button--theme-green {
    --color-main: var(--color-green-40);
    --color-hover: var(--color-green-60);
    --color-pressed: var(--color-green-80);
  }

  &.u-button--theme-blue {
    --color-main: var(--color-dark-blue-60);
    --color-hover: var(--color-dark-blue-80);
    --color-pressed: var(--color-dark-blue-dark);
  }

  &.u-button--theme-portal {
    --color-main: var(--color-portal-indigo-900);
    --color-hover: var(--color-portal-indigo-700);
    --color-pressed: var(--color-portal-indigo-500);
  }

  &.u-button--theme-white {
    --color-main: white;
    --color-hover: var(--color-u-contrast);
    --color-pressed: var(--color-slate-300);
  }

  &.u-button--theme-skin {
    --color-main: var(--theme-darkest);
    --color-hover: var(--theme-light);
    --color-pressed: var(--theme-dark);
    --color-focus: var(--theme-light);
    @apply hover:brightness-[115%];
  }

  &.u-button--theme-skin-medium {
    --color-main: var(--theme-dark);
    --color-hover: var(--theme-dark);
    --color-pressed: var(--theme-dark);
    --color-focus: var(--theme-dark);
    @apply hover:brightness-[85%];
  }

  &.u-button--quite {
    color: var(--button-color);
  }

  &.u-button--outline {
    border-color: var(--button-color);
  }

  &.u-button--filled,
  &.u-button--active {
    background-color: var(--button-color);

    & > :deep(*) {
      /* While color-contrast is not supported, we need to set this manually when needed */
      color: var(--color, black);

      @supports (color: color-contrast(gray vs black, white)) {
        color: color-contrast(var(--button-color) vs black, white);
      }
    }
    &.u-button--mobile {
      @apply opacity-100;
      --color-hover: white;
      --color-focus: white;
      --color-pressed: white;
    }
  }

  &.u-button--pill,
  &.u-button--mobile {
    border-radius: 10rem;
  }

  &.u-button--mobile {
    @apply opacity-80;
  }

  &.u-button--cta {
    background-color: var(--theme-light);
  }

  &.u-button--stacked,
  &.u-button--mobile {
    position: relative;
    overflow: visible;
    & .u-button__text {
      position: absolute;
      overflow: visible;
      text-align: center;
      inset: 100% 0 auto;
      padding-inline: 0 !important;
      padding-block-start: var(--button-padding-block);
      display: grid;
      place-content: center;
      color: currentColor;
      font-size: 1.125rem;
    }
  }

  &:where(.u-button--active) {
    --button-color: white;
  }

  & .u-button__text {
    flex: 1 1 0;
    overflow: clip visible;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:first-child {
      padding-inline-start: var(--button-padding-inline);
    }

    &:last-child {
      padding-inline-end: var(--button-padding-inline);
    }
  }
}
</style>
