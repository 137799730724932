export const productForms = {
  "matematikk-for-oekonomer": {
    url: "https://share-eu1.hsforms.com/1wYAJlfMRSNmsFfppFRq5ywfd56p",
    subTitle:
      "Kunne matematikk for økonomer forventes lansert høsten 2024.\n" +
      "Registrer deg for å motta oppdateringer og være blant de første som får beskjed når det er tilgjengelig.",
  },
  "kvantitativ-metode": {
    url: "https://share-eu1.hsforms.com/1ud-XCVCTS_aQpWZsBr83kAfd56p",
    subTitle:
      "Kunne kvantitativ metode forventes lansert høsten 2024.\n" +
      "Registrer deg for å motta oppdateringer og være blant de første som får beskjed når det er tilgjengelig.",
  },
} as const satisfies Record<
  FormProductKeys,
  { url: string; subTitle: string }
>

export const formProductKeys = [
  "matematikk-for-oekonomer",
  "kvantitativ-metode",
] as const

export type FormProductKeys = (typeof formProductKeys)[number]
