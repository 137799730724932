import type { Criterion, Options, Query } from "~/models/Search"
import { debounce } from "~/utilities/debounce"
import { formatSearchResults } from "~/mapping/formatSearchResults"
import { SearchResult } from "~/models/Content/SearchResult"
import useSearchHelper from "~/composables/useSearchHelper"

function createKeyValueParams(
  object: Record<string, string>,
  field: string,
): string {
  const fieldsParam: string[] = []
  Object.keys(object).forEach((key, index) => {
    fieldsParam.push(
      `${field}[${key}]=${encodeURIComponent(object[key])}`,
    )
  })
  return fieldsParam.join("&")
}

function buildQuery(
  query: Query,
  criterion: Criterion,
  limit: number,
  offset: number,
) {
  let queryString = `fullText=${query.text || "*"}`
  if (query.fuzziness) {
    queryString += `&fuzziness=${query.fuzziness}`
  }
  if (query.spellcheck) {
    queryString += "&spellcheck=1"
  }
  if (criterion.contentTypeCriterion?.length) {
    queryString += `&${criterion.contentTypeCriterion.map(value => `contentType[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.subtreeCriterion?.length) {
    queryString += `&${criterion.subtreeCriterion.map(value => `subtrees[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.labelFieldCriterion?.length) {
    queryString += `&${criterion.labelFieldCriterion.map(value => `label[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.activityFieldCriterion?.length) {
    queryString += `&${criterion.activityFieldCriterion.map(value => `activity[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.gradeFieldCriterion?.length) {
    queryString += `&${criterion.gradeFieldCriterion.map(value => `grade[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.contentIdCriterion?.length) {
    queryString += `&${criterion.contentIdCriterion.map(value => `contentId[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.parentLocationIdCriterion?.length) {
    queryString += `&${criterion.parentLocationIdCriterion.map(value => `parentLocationId[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.locationIdCriterion?.length) {
    queryString += `&${criterion.locationIdCriterion.map(value => `locationId[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.accessStateCriterion?.length) {
    queryString += `&${criterion.accessStateCriterion.map(value => `access[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion?.sortField) {
    queryString += `&sortField=${criterion.sortField.toLowerCase()}`
  }
  if (criterion?.sortOrder) {
    queryString += `&sortOrder=${{ asc: "ascending", desc: "descending" }[criterion.sortOrder]}`
  }
  if (query.boost) {
    queryString += `&${createKeyValueParams(query.boost, "boost")}`
  }
  if (query.fields?.length) {
    queryString += `&fields=${query.fields.join(",")}`
  }
  return `${queryString}&mainLocation=${criterion.mainLocationCriterion ? 1 : 0}&limit=${limit}&offset=${offset}`
}

export function useSearch<T>(
  path: string,
  { transformData = data => data, debounceDuration = 1_000 }: Options,
) {
  if (!path) {
    throw new Error("'path' option is required")
  }

  const { emptyQuery } = useSearchHelper()

  const results = ref<T>()
  const isLoading = ref(false)
  const hasFailed = ref(false)

  async function fetchResults(
    query: Query,
    criterion: Criterion = {},
    limit: number = 10,
    offset: number = 0,
  ): Promise<void> {
    isLoading.value = true
    hasFailed.value = false
    try {
      const response = (
        await api.ibexaApi.get(
          `${path}?${buildQuery(query, criterion, limit, offset)}`,
        )
      ).data

      try {
        results.value = transformData(response)
      } catch (error) {
        console.error("Error formatting search results:", error)
      }
    } catch (error) {
      hasFailed.value = true
      throw error
    } finally {
      isLoading.value = false
    }
  }

  async function fetchResultsV2(
    query: Query,
    criterion: Criterion = {},
    limit: number = 10,
    offset: number = 0,
    depth: number = 0,
    maxDepth: number = 3,
  ): Promise<SearchResult> {
    isLoading.value = true
    hasFailed.value = false

    try {
      const results = await fetchAndFormatResults(
        query,
        criterion,
        limit,
        offset,
      )
      return await enrichWithRelations(results, depth, maxDepth)
    } catch (error) {
      hasFailed.value = true
      throw error
    } finally {
      isLoading.value = false
    }
  }

  // Helper functions
  async function fetchAndFormatResults(
    query: Query,
    criterion: Criterion,
    limit: number,
    offset: number,
  ): Promise<SearchResult> {
    const queryString = buildQuery(query, criterion, limit, offset)

    try {
      const response = await api.ibexaApi.get(
        `${path}?${queryString}`,
      )
      return formatSearchResults(response.data, queryString)
    } catch (error) {
      console.error("API request failed:", error)
      throw error
    }
  }

  async function enrichWithRelations(
    results: SearchResult,
    depth: number,
    maxDepth: number,
  ): Promise<SearchResult> {
    const relationsIds = results.items
      .map(item => item.relationIds)
      .flat()

    if (!relationsIds.length || depth >= maxDepth) {
      return results
    }

    try {
      results.relations = await fetchResultsV2(
        emptyQuery,
        { contentIdCriterion: relationsIds },
        100,
        0,
        depth + 1,
        maxDepth,
      )
      return results
    } catch (error) {
      console.error("Failed to fetch relations:", error)
      results.relations = null
      return results
    }
  }

  return {
    isLoading,
    hasFailed,
    results,
    fetchResults,
    fetchResultsV2,
    debounceResults: debounce(fetchResults, debounceDuration),
  }
}

export default useSearch
