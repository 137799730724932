<script setup>
const router = useRouter()
const route = useRoute()
</script>

<template>
  <div
    class="container flex flex-col items-center justify-center py-40 text-white"
  >
    <p class="mb-10 text-center">
      Oi, det oppstod en feil. Kontakt oss gjerne via kundeservice
      hvis problemet vedvarer.
      <br /><span v-if="route.query.r">
        Feilmelding: {{ route.query.r }}</span
      >
    </p>
    <div
      class="flex flex-col items-center justify-center gap-5 md:flex-row"
    >
      <UButtonNew
        theme="light"
        variant="filled"
        @click="router.back()"
        ><template #prepend
          ><UIcon name="arrow-left" size="sm" /></template
        >Tilbake</UButtonNew
      >
      <UButtonNew
        href="/om/kundeservice"
        theme="light"
        variant="filled"
        ><template #prepend><UIcon name="user" size="sm" /></template
        >Kundeservice</UButtonNew
      >
    </div>
  </div>
</template>

<route lang="yaml">
name: "404"
meta:
  title: 404
  layout: 404
</route>
