export const subject__subject_page_title_position: Record<
  string,
  string
> = {
  "0": "top",
  "1": "middle",
  "2": "bottom",
}

export const subject__chapter_type: Record<string, string> = {
  "0": "Kun fargefyll",
  "1": "Fargefyll med illustrasjon (.png)",
  "2": "Heldekkende bilde",
}
