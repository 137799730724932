<script setup lang="ts">
const products = useProductVariantsStore()

const isLastElement = (index: number) => {
  return (
    products.activeProduct?.productCurriculumFor &&
    index !== products.activeProduct?.productCurriculumFor.length - 1
  )
}
</script>

<template>
  <section
    v-if="
      products.activeProduct &&
      (products.activeProduct.productCurriculumFor.length > 0 ||
        products.activeProduct.vanitySentence)
    "
    class="flex flex-col items-center justify-center w-full px-8 space-y-3 text-xl md:m-auto md:px-24"
  >
    <div class="flex items-center justify-center mb-4">
      <h2
        class="font-national2condensed text-marketing-mobile-h2-h2-medium-italic italic text-[--theme-dark] md:text-marketing-desktop-h2-h2-l-italic text-center"
      >
        Passer for deg som studerer på
      </h2>
    </div>
    <p
      v-if="products.activeProduct?.vanitySentence"
      class="flex items-center justify-center font-national2condensed text-marketing-mobile-p1-p1-small-national-regular text-[--theme-dark] md:text-marketing-desktop-p1-p1-large-national-r"
    >
      {{ products.activeProduct?.vanitySentence }}
    </p>

    <div
      v-else
      class="flex flex-wrap items-center justify-center gap-2 :md:gap-5"
    >
      <div
        v-for="(institute, index) in products.activeProduct
          ?.productCurriculumFor"
        :key="institute[0]"
      >
        <div class="flex items-center :md:gap-x-5 gap-x-2">
          <div
            class="flex flex-wrap items-center justify-center space-x-1"
          >
            <p
              class="font-national2condensed text-marketing-mobile-p1-p1-small-national-bold text-[--theme-darker] md:text-marketing-desktop-p1-p1-large-national-b"
            >
              {{ institute[0] }}
            </p>
            <p
              class="font-national2condensed text-marketing-mobile-p1-p1-small-national-regular text-[--theme-darker] md:text-marketing-desktop-p1-p1-large-national-r"
            >
              {{ institute[1] }}
            </p>
          </div>
          <div
            v-if="isLastElement(index)"
            class="aspect-[1/1] w-2 rounded-full bg-[--theme-light] md:w-3"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>
