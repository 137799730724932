<script setup lang="ts">
import { stringToHTML } from "~/utilities/composables"

const props = defineProps({
  html: {
    type: String as PropType<string>,
    default: "",
  },
})

const route = useRoute()
const router = useRouter()

const content = computed(() => {
  const html = stringToHTML(props.html) as Element
  html.querySelector("h1")?.remove()
  return html.innerHTML
})

const ACTIVE_CLASS = "text-link--active-target"

function handleLinks(event: Event) {
  const link = (event.target as HTMLElement)?.closest("a")
  const href = link?.getAttribute("href")

  if (href?.startsWith("#")) {
    event.preventDefault()

    if (link) {
      clearActiveLinks()
      link.classList.add(ACTIVE_CLASS)
    }

    if (route.hash === href) {
      router.back()
    } else {
      route.hash
        ? router.replace({ ...route, hash: href })
        : router.push({ ...route, hash: href })
    }
  }
}

function clearActiveLinks() {
  document
    .querySelectorAll(`.${ACTIVE_CLASS}`)
    .forEach(el => el.classList.remove(ACTIVE_CLASS))
}

onBeforeRouteUpdate(({ hash }) => {
  if (!hash) {
    clearActiveLinks()
  }
})

onMounted(() => {
  const link = document.querySelector(`a[href="${route.hash}"]`)
  if (link) {
    link.classList.add(ACTIVE_CLASS)
  }
})
</script>

<template>
  <div
    v-if="content"
    class="richtext [&.math-inline]:overflow-x-auto [&_.math-block]:overflow-x-auto [&_.math-block]:py-7 [&_.math-block]:pl-6 md:[&_.math-block]:pl-10"
    @click="handleLinks"
    v-html="content"
  />
</template>

<style lang="postcss" scoped>
/* Math elements can have style attributes which sets the wrong font. This style ensures the font is always Arial
with correct letter spacing .*/
:deep(math) {
  font-family: "Arial", sans-serif !important;
  letter-spacing: 0.1rem;
}

/* Numbers inside fractions in math elements looks smaller than their applied font. This style ensures the numbers
inside fractions looks similar as the numbers outside fractions, and that the fractions have spacing below them
so they are more readable when combined with text or other fractions. Fractions inside superscript elements should not
be enlarged.
 */
:deep(math mfrac):not(msup > mfrac) {
  font-size: 25px;
  height: fit-content;
  padding-bottom: 0.5rem;
  @screen md {
    font-size: 33px;
  }
}

/* Styles specifically for fractions in Safari */
@supports (-webkit-backdrop-filter: blur(10px)) {
  :deep(math mfrac):not(msup > mfrac) {
    font-size: revert;
  }
}
</style>
