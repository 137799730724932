<script setup lang="ts">
interface Toast {
  id: string
  message: string
  type?: "error" | "success" | "info"
  action?: string
}

const toastStore = useToastStore()

const getToastTitle = (toast: Toast) => {
  switch (toast.type) {
    case "error":
      return "Feil"
    case "success":
      return "Suksess"
    case "info":
      return "Informasjon"
    default:
      return "Informasjon"
  }
}
</script>

<template>
  <section class="gui-toast-group">
    <output
      v-for="(toast, key) in toastStore.toasts"
      :id="toast.id"
      :key="key"
      :class="{
        'bg-red-dark': toast.type === 'error',
        'bg-green-dark': toast.type === 'success',
        'bg-dark-blue-dark': toast.type === 'info' || !toast.type,
      }"
      role="status"
      class="gui-toast"
    >
      <div class="flex flex-col gap-4 p-4">
        <h2 v-if="toast.type" class="text-2xl capitalize">
          {{ getToastTitle(toast) }}
        </h2>
        <span class="self-center text-lg">{{ toast.message }}</span>
        <span v-if="toast.action" class="text-lg">{{
          toast.action
        }}</span>
      </div>
      <UButtonNew
        variant="quite"
        theme="white"
        class="hover:brightness-75"
        circle
        @click="toastStore.removeToast(toast.id)"
      >
        <template #prepend>
          <u-icon name="close" size="sm" />
        </template>
      </UButtonNew>
    </output>
  </section>
</template>

<style scoped lang="postcss">
.gui-toast-group {
  @apply fixed
  end-[5vh]
  start-[5vh]
  top-[5dvh]
  z-50
  grid
  justify-end
  justify-items-center
  gap-[1vh]
  md:start-[50vh]
  md:justify-items-end
  lg:start-[70vh];
}

.gui-toast {
  @apply flex gap-3 rounded-[10px] pl-3 font-national2 text-lg leading-[22px] text-white;
  max-inline-size: min(31ch, 90vw);
}
</style>
