import type {
  SearchResult,
  SearchItem,
  Field,
  FieldsTypes,
} from "~/models/Content/SearchResult"
import type {
  LocationHit,
  BaseSearchResponse,
} from "~/models/Content/Response"
import { ContentType } from "~/models/Content/ContentType"
import {
  formatStringField,
  formatTextField,
  formatRichTextField,
  formatImageAssetField,
  formatObjectRelationField,
  formatTagsField,
  formatBooleanField,
  formatAuthorField,
  formatSelectionField,
  formatImageField,
} from "./formatFieldValues"

const formatFieldValue = (
  fieldType: keyof FieldsTypes,
  fieldValue: unknown,
  contentType?: string,
  fieldKey?: string,
) => {
  switch (fieldType) {
    case "ezstring":
      return formatStringField(fieldValue)
    case "eztext":
      return formatTextField(fieldValue)
    case "ezrichtext":
      return formatRichTextField(fieldValue)
    case "ezimage":
      return formatImageField(fieldValue)
    case "ezimageasset":
      return formatImageAssetField(fieldValue)
    case "ezobjectrelation":
    case "ezobjectrelationlist":
      return formatObjectRelationField(fieldValue)
    case "eztags":
      return formatTagsField(fieldValue)
    case "ezboolean":
      return formatBooleanField(fieldValue)
    case "ezauthor":
      return formatAuthorField(fieldValue)
    case "ezselection":
      return formatSelectionField(
        fieldValue,
        contentType || "",
        fieldKey || "",
      )
    default:
      return fieldValue
  }
}

const formatSearchHits = (
  result: BaseSearchResponse,
): SearchResult => {
  if (!result?.View?.Result?.searchHits?.searchHit) {
    return {
      query: "",
      count: 0,
      items: [],
      relations: null,
    }
  }

  const firstHit = result.View.Result.searchHits.searchHit[0]
  if (!firstHit) {
    return {
      query: "",
      count: 0,
      items: [],
      relations: null,
    }
  }

  const items: SearchItem[] =
    result.View.Result.searchHits.searchHit.map(
      (hit: LocationHit) => {
        const fields: Field[] = []
        const version =
          hit.value.Location.ContentInfo.Content.CurrentVersion
            .Version

        // Map all fields from the response
        version.Fields.field.forEach(field => {
          const fieldType =
            field.fieldTypeIdentifier as keyof FieldsTypes

          // Only add supported field types
          if (fieldType as keyof FieldsTypes) {
            fields.push({
              type: fieldType,
              value: formatFieldValue(
                fieldType,
                field.fieldValue,
                hit.value.Location.ContentInfo.ContentType
                  ._identifier,
                field.fieldDefinitionIdentifier,
              ) as FieldsTypes[typeof fieldType],
              rawValue: JSON.stringify(field.fieldValue),
              key: field.fieldDefinitionIdentifier,
            })
          }
        })

        // Get all relation IDs from fields of type ezobjectrelation(s)
        const relationIds = version.Relations.Relation.map(
          relation => {
            const href = relation.DestinationContent._href
            // Extract the ID from the end of the href path "/api/ezp/v2/content/objects/7073"
            const id = Number(href.split("/").pop())
            return id
          },
        )

        return {
          id: hit.value.Location.ContentInfo.Content._id,
          contentType: hit.value.Location.ContentInfo.ContentType
            ._identifier as ContentType,
          fields,
          relationIds,
        }
      },
    )

  return {
    query: "",
    count: result.View.Result.count,
    items,
    relations: null,
  }
}

const formatSearchResults = (
  result: BaseSearchResponse,
  query: string,
): SearchResult => {
  const formattedResults = formatSearchHits(result)
  formattedResults.query = query

  logSearchResults(formattedResults, {
    logEmpty: false,
    logSummary: true,
    logItems: true,
    logFields: true,
    contentTypes: [],
    fieldTypes: ["ezobjectrelation"],
  })

  return formattedResults
}

export { formatSearchResults }

interface LogConfig {
  logEmpty?: boolean
  logSummary?: boolean
  logItems?: boolean
  logFields?: boolean
  contentTypes?: ContentType[]
  fieldTypes?: Array<keyof FieldsTypes>
}

const logSearchResults = (
  formattedResults: SearchResult,
  logConfig: Required<LogConfig>,
) => {
  // Skip logging if no results and logEmpty is false
  if (formattedResults.items.length === 0 && !logConfig.logEmpty) {
    return
  }

  // Skip logging if content type doesn't match filter
  if (
    logConfig.contentTypes.length > 0 &&
    !logConfig.contentTypes.includes(
      formattedResults.items[0].contentType,
    )
  ) {
    return
  }

  // Print results in table format
  if (logConfig.logSummary) {
    console.table({
      query: formattedResults.query,
      contentType: formattedResults.items[0].contentType,
      totalCount: formattedResults.count,
      itemsCount: formattedResults.items.length,
    })
  }

  // Print items details if any exist
  if (formattedResults.items.length > 0) {
    if (logConfig.logItems) {
      console.table(
        formattedResults.items.map(item => ({
          id: item.id,
          fieldCount: item.fields.length,
          relationCount: item.relationIds.length,
          relationIds: JSON.stringify(item.relationIds),
        })),
      )
    }

    if (logConfig.logFields) {
      // Print fields for each item
      formattedResults.items.forEach(item => {
        console.log(`Fields for item ${item.id}:`)
        let filteredFields
        if (logConfig.fieldTypes?.length) {
          filteredFields = item.fields.filter(field =>
            logConfig.fieldTypes.includes(field.type),
          )
        } else {
          filteredFields = item.fields
        }

        console.table(
          filteredFields.map(field => ({
            key: field.key,
            type: field.type,
            value: JSON.stringify(field.value),
            rawValue: field.rawValue,
          })),
        )
      })
    }
  }
}
