<script setup lang="ts">
import { articleTemplates } from "~/utilities/articles"

const route = useRoute<"product">()
const menu = [
  {
    name: "Video",
    icon: "video",
    to: `/${route.params.product}/${
      route.params.variant
    }/sok?template=${articleTemplates.get("video")?.ibexaId ?? 0}`,
  },
  {
    name: "Oppgaver",
    icon: "check",
    to: `/${route.params.product}/${
      route.params.variant
    }/sok?template=${articleTemplates.get("quiz")?.ibexaId ?? 0}`,
  },
]
</script>

<template>
  <div
    class="flex w-full flex-col items-center justify-start text-white"
  >
    <h1
      class="mt-10 flex w-full items-center justify-start whitespace-nowrap pl-6 font-national2compressed text-5xl font-bold"
    >
      Kategorier
    </h1>
    <ul class="mt-6 flex w-full flex-col gap-5 md:flex-row">
      <li
        v-for="{ name, icon, to } in menu"
        :key="name"
        class="grid w-full"
        style="aspect-ratio: 3/2"
      >
        <router-link
          class="flex min-h-10 min-w-10 flex-col items-center justify-center gap-2 rounded-xl bg-[--theme-dark] p-4 text-white"
          :to="`/produkter${to}`"
        >
          <u-icon :name="icon" size="sm" />
          {{ name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="postcss"></style>

<route lang="yaml">
meta:
  title: Kategorier
  layout: product
</route>
