<script setup lang="ts">
import { PlayPauseState } from "./UAudioPlayer.vue"
const props = defineProps({
  playPauseState: {
    type: String as PropType<PlayPauseState>,
    default: "pause",
  },
  handlePlay: {
    type: Function as PropType<() => void>,
    default: undefined,
  },
  handleSkip: {
    type: Function as PropType<(value: number) => void>,
    default: undefined,
  },
  isFullscreen: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
</script>
<template>
  <div class="flex w-fit items-center justify-between gap-2">
    <UButtonNew
      v-if="props.handleSkip"
      aria-label="Hopp 15 sekunder tilbake"
      variant="quite"
      theme="white"
      class="scale-75 hover:text-lighter"
      :class="{ 'hidden lg:block': !props.isFullscreen }"
      @click="() => props.handleSkip!(-15)"
    >
      <UIcon name="skip-15-back" size="xl" />
    </UButtonNew>
    <UButtonNew
      v-if="props.handlePlay"
      :aria-label="
        props.playPauseState === 'play-filled'
          ? 'Spill av'
          : 'Sett på pause'
      "
      variant="outline"
      circle
      theme="light"
      @click="() => props.handlePlay!()"
    >
      <UIcon :name="props.playPauseState" />
    </UButtonNew>
    <UButtonNew
      v-if="props.handleSkip"
      aria-label="Hopp 15 sekunder fremover"
      variant="quite"
      theme="white"
      class="scale-75 hover:text-lighter"
      :class="{ 'hidden lg:block': !props.isFullscreen }"
      @click="() => props.handleSkip!(15)"
    >
      <UIcon name="skip-15-forward" size="xl" />
    </UButtonNew>
  </div>
</template>

<style lang=""></style>
