<script setup lang="ts">
import { Article } from "~/types/article"

const products = useProductContentStore()

const personalization = usePersonalizationStore()

const articles = computed(() => {
  return products.articles
})

function getArticleURL(article: Article) {
  return `/produkter/${
    article.href.split("/")[1].replace(/-(?!.*-)/, "/") +
    "/" +
    article.href.split("/")[2] +
    "/" +
    article.href.split("/")[3]
  }`
}

onMounted(() => {
  if (personalization.lastActiveArticle) {
    const id = personalization.lastActiveArticle.id
    const element = document.getElementById(id.toString())
    if (element) {
      setTimeout(() => {
        element.scrollIntoView()
        window.scrollBy(0, -200)
      }, 100)
    }
  }
})
</script>

<template>
  <nav
    class="w-full max-w-[70rem] pb-32 pt-[1.5em] md:w-[21rem] lg:w-full xl:pt-[2.5em]"
  >
    <ul
      v-if="articles.length"
      id="article-cards"
      :key="0"
      class="flex flex-col gap-10"
    >
      <li
        v-for="(article, key) in articles.filter(a => !a.restricted)"
        :key="key"
      >
        <router-link
          data-pendo="article-card"
          :to="getArticleURL(article)"
          class="mx-auto flex h-[300px] w-full max-w-[350px] cursor-pointer flex-col gap-3 transition-transform duration-150 ease-linear md:w-full md:max-w-none md:hover:scale-[101%] lg:h-[500px]"
        >
          <UPreview :article="article" />
        </router-link>
      </li>
      <li
        v-for="(article, key) in articles.filter(a => a.restricted)"
        :key="key"
      >
        <div
          data-pendo="article-locked-card"
          class="mx-auto flex h-[300px] w-full max-w-[350px] cursor-default flex-col gap-3 transition-transform duration-150 ease-linear md:w-full md:max-w-none lg:h-[500px]"
        >
          <UPreview :article="article" />
        </div>
      </li>
    </ul>
    <u-skeleton-card-list v-else :key="1" />
  </nav>
</template>

<route lang="yaml">
name: subject
meta:
  layout: subject
</route>
