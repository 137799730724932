import { ReadArticle } from "~/types/readHistory"

/**
 * Cleans the read history array before saving to backend
 * Keeps full article details only for the first article of each productId
 * @param history Array of read articles
 * @returns Cleaned array with minimal data for non-first articles
 */
export function cleanReadHistory(
  history: ReadArticle[],
): ReadArticle[] {
  // Group articles by productId
  const productGroups = new Map<number, ReadArticle[]>()

  history.forEach(article => {
    const group = productGroups.get(article.productId) || []
    group.push(article)
    productGroups.set(article.productId, group)
  })

  // Map through history and clean non-first articles
  return history.map(article => {
    const productGroup = productGroups.get(article.productId) || []
    const isFirstArticle = productGroup[0]?.id === article.id

    if (isFirstArticle) {
      return article // Keep all properties for first article
    }

    // For other articles, only keep essential properties
    return {
      id: article.id,
      productId: article.productId,
      subjectId: article.subjectId,
      priority: article.priority,
      date: article.date,
      done: article.done,
    } as ReadArticle
  })
}
