import type { Licence } from "~/types/licence.d"

import { acceptHMRUpdate, defineStore } from "pinia"

import { getLicences } from "~/mapping/external"

export const useLicenceStore = defineStore("licence", () => {
  /** State */

  const licenceData = ref<Licence[] | null>(null)
  const activeLicence = ref<Licence | null>(null)

  const hasValidLicence = computed(() => {
    return !!licenceData.value?.find(
      licence => new Date(licence.endDate) > new Date(),
    )
  })

  const hasLicenceByEan = (ean: string): boolean => {
    return !!licenceData.value?.find(
      licence =>
        licence.ean === ean && new Date(licence.endDate) > new Date(),
    )
  }
  const validLicenceData = computed(() => {
    return licenceData.value?.filter(
      licence => new Date(licence.endDate) > new Date(),
    )
  })
  /** Actions */
  const setLicenceData = async () => {
    const data = await getLicences()
    licenceData.value = data
  }

  const setActiveLicence = (ean?: string) => {
    const licence = validLicenceData.value?.find(
      licence => licence.ean === ean,
    )
    activeLicence.value = licence || null
  }

  return {
    /** State */
    licenceData,
    activeLicence,
    /** Actions */
    setLicenceData,
    hasLicenceByEan,
    setActiveLicence,
    //getActiveLicence,
    /** Computed */
    hasValidLicence,
    validLicenceData,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useLicenceStore, import.meta.hot),
  )
