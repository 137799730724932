// these APIs are auto-imported from @vueuse/core
import NProgress from "nprogress"
import CryptoJS from "crypto-js"

export { NProgress }
export const isDark = useDark()

export const toggleDark = useToggle(isDark)

export const setBg = (color: string) => {
  const html = document.querySelector("html") as HTMLElement
  html.removeAttribute("class")
  html.classList.add(`bg-${color}`)
  //   html.classList.add(`bg-${color}`, 'transition-colors', 'duration-300')
}

/**
 *
 * @param name
 * @returns a URI friendly string
 */
export const encodeUri = (name: string | string[] | undefined) =>
  encodeURIComponent(String(name).replace(" ", "-")).toLowerCase()

/**
 * Makes sure that an array does not exceed 1 MB
 */
export function monitorHistoryArraySize(jsonArray: any[]) {
  const jsonString = JSON.stringify(jsonArray, null, 2)
  const sizeInBytes = new Blob([jsonString]).size
  const sizeInMB = sizeInBytes / (1024 * 1024)
  if (sizeInMB > 1) {
    console.warn(
      "Warning: The size of the readHistory object exceeded 1 MB.",
    )
    console.warn(
      "The number of items in the array is now : ",
      jsonArray.length,
    )
    return
  }
}

/**
 *
 * @param str
 * @returns a string with first letter being capitalized
 */
export const capitalizeChar = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

/**
 * Only characters
 * @param str
 * @returns a string with only characters
 */
export function onlyChar(str: string) {
  return str.replace(/[^\w\s]/gi, "")
}

/**
 * Camelize string
 * @param str
 * @returns
 */
export function camalize(str: string): string {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
}

/**
 * Convert pixels to viewport height
 * @param px
 * @returns
 */
export function convertPXToVH(px: number | undefined) {
  if (px) return px * (100 / document.documentElement.offsetHeight)
  return 0
}

/**
 * Create a random string
 * @param length
 * @returns a string
 */
export function createRandomString(length: number) {
  return CryptoJS.lib.WordArray.random(length)
}

/**
 * Checks if value is of type JSON
 * @param {String} str
 * @returns a boolean value
 */
export function isJSON(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

/**
 * Convert a template string into HTML DOM nodes
 * @param  {String} str The template string
 * @return {Node}       The template HTML
 */
export const stringToHTML = (
  str: string | (() => Promise<{ [key: string]: any }>),
) => new DOMParser().parseFromString(str as string, "text/html").body

/**
 * Convert milliseconds to minutes and seconds
 * @param {Number} millis milliseconds
 * @returns a numeric value
 */
export function millisToMinutesAndSeconds(millis: number) {
  const minutes = Math.floor(millis / 60000)
  const seconds = ((millis % 60000) / 1000).toFixed(0)
  const result =
    minutes + "." + (Number(seconds) < 10 ? "0" : "") + seconds
  return Number(result)
}

/**
 * Convert millisecond to seconds and decimal milliseconds
 * @param {Number} millis milliseconds
 * @returns a numeric value
 */
export function millisToSecondsAndDecimal(millis: number) {
  return Number((millis / 1000).toFixed(3))
}

/**
 * Convert milliseconds to seconds
 * @param {Number} millis milliseconds
 * @returns a numeric value
 */
export function millisToSeconds(millis: number) {
  return Math.floor(millis / 1000)
}

/**
 * Get a localstorage item
 * @param {String} key
 * @returns the localStorage item
 */
export function getLocalStorageItem(key: string): any {
  const ssr = import.meta.env.SSR
  return !ssr && JSON.parse(String(localStorage.getItem(key)))
}

export function parseQuery(queryString: string) {
  const query = {} as Record<string, string>

  const pairs = (
    queryString[0] === "?" ? queryString.substring(1) : queryString
  ).split("&")
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=")
    query[decodeURIComponent(pair[0])] = decodeURIComponent(
      pair[1] || "",
    )
  }
  return query
}

/**
 * Formats any deep nested object to a query string
 * @param data
 */
export function getParam(data: Record<string, any>) {
  return Object.keys(data)
    .map(key => {
      let val = data[key]
      if (val !== null && typeof val === "object") val = getParam(val)
      return `${key}=${encodeURIComponent(
        `${val}`.replace(/\s/g, "_"),
      )}`
    })
    .join("&")
}

/**
 * Formats date to Norwegian format
 * @param { 0: number; 1: number; 2: number } dateObj,
 * where 0 is year, 1 is month and 2 is day, or string
 * @returns a string
 */
export const formatDate = (
  dateRaw: { 0: number; 1: number; 2: number } | string | undefined,
) => {
  if (!dateRaw || !Object.keys(dateRaw).length) return ""

  let date: null | Date = null
  if (typeof dateRaw === "string") {
    date = new Date(dateRaw)
  }

  if (typeof dateRaw === "object") {
    date = new Date(dateRaw[0], dateRaw[1] - 1, dateRaw[2])
  }

  if (date === null) return ""

  return new Intl.DateTimeFormat("nb", { dateStyle: "long" }).format(
    date,
  )
}

export const formatForURL = (url: string) => {
  return decodeURIComponent(url.toLowerCase())
    .replaceAll(/æ/g, "ae")
    .replaceAll(/å/g, "aa")
    .replaceAll(/ø/g, "oe")
    .replaceAll(/\s/g, "-")
    .replaceAll(/[,;:]/g, "")
}
