<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components"

defineProps({
  modelValue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const emit = defineEmits(["update:modelValue"])

function clickOutside() {
  emit("update:modelValue", false)
}
</script>

<template>
  <transition name="fade-in-zoom" mode="out-in">
    <div
      v-if="modelValue"
      v-on-click-outside="clickOutside"
      class="top-15 absolute z-30 w-full overflow-hidden shadow-lg"
    >
      <slot />
    </div>
  </transition>
</template>
