<script setup lang="ts">
import type { Subject } from "~/types/subject"
import type { Folder } from "~/types/folder"
import { getColorScheme } from "~/utilities/colors"
import { useMediaCounts } from "~/composables/useMediaCounts"

interface MediaTypes {
  i: string
  count: number
  text: string
}

const props = defineProps({
  data: {
    type: Object as PropType<Subject | Folder>,
    required: true,
  },
  backgroundImageClass: {
    type: String,
    default: "",
  },
})

const theme = inject("theme") as ComputedRef<string>
const colorScheme = computed(() => {
  return getColorScheme(props.data.colorTag ?? "", theme.value)
})

const mediaCounts = useMediaCounts(props.data)

const mediaTypes = computed<MediaTypes[]>(() => [
  {
    i: "article",
    count: mediaCounts.data.value?.articles ?? 0,
    text:
      mediaCounts.data.value?.articles === 1
        ? "artikkel"
        : "artikler",
  },
  {
    i: "video",
    count: mediaCounts.data.value?.videos ?? 0,
    text: mediaCounts.data.value?.videos === 1 ? "video" : "videoer",
  },
  {
    i: "medal",
    count: mediaCounts.data.value?.quizes ?? 0,
    text:
      mediaCounts.data.value?.quizes === 1
        ? "øvingsoppgave"
        : "øvingsoppgaver",
  },
])
</script>

<template>
  <ul class="flex gap-x-2 gap-y-1 lg:flex-col">
    <li
      v-for="(el, k) in mediaTypes"
      :key="k"
      :class="[
        { '!hidden': !el.count },
        props.backgroundImageClass || colorScheme.bwText,
      ]"
      class="flex items-center gap-2 text-xl duration-200 w-fit"
    >
      <u-icon :name="el.i" size="lg" class="text-inherit" />
      <div class="text-nav-element flex text-[18px] text-inherit">
        <span>{{ el.count }}&nbsp;</span>
        <span class="hidden lg:inline">{{ el.text }}</span>
      </div>
    </li>
  </ul>
</template>

<style scoped lang="postcss">
.text-nav-element span {
  @apply font-national2 text-[18px];
}

.text-nav-element {
  @apply font-national2 text-[22px] font-normal not-italic;
}
</style>
