<script setup lang="ts">
defineProps({
  preview: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  plainText: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  parentLocationId: Number,
  subjectPage: {
    type: Boolean,
    default: false,
  },
  isDark: {
    type: Boolean,
    default: false,
  },
})

const data = useProductContentStore()

const subject = computed(() => data.activeSubject)
const shortTitle = ref("")
const subjectHref = ref("")
const subjectPriority = ref("")

watch(subject, () => {
  shortTitle.value = subject.value?.shortTitle as string
  subjectHref.value = subject.value?.href as string
  subjectPriority.value = subject.value?.priority as unknown as string
})

const checkSubject = () =>
  (subjectHref.value && shortTitle.value) ||
  (subject.value?.href && subject.value?.shortTitle)

const route = useRoute<"article">()
const subjectParam = route.params.subject
const productParam = route.params.product
const variantParam = route.params.variant
</script>

<template>
  <p
    v-if="checkSubject() && !plainText"
    class="mb-2 line-clamp-2 font-national2 text-xl font-extrabold uppercase xl:text-2xl"
  >
    <a
      class="border-b-2 border-u-contrast"
      :class="{
        '!border-lighter !text-lighter': isDark,
      }"
      :href="`/produkter/${productParam}/${variantParam}/${subjectParam}`"
      >{{ subject?.priority || subjectPriority }}.
      {{ shortTitle || subject?.shortTitle }}</a
    >
  </p>
  <p
    v-if="checkSubject() && plainText"
    class="border-b-2 border-u-contrast"
  >
    {{ subject?.priority || subjectPriority }}.
    {{ shortTitle || subject?.shortTitle }}
  </p>
</template>
