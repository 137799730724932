import { defineStore } from "pinia"
import { useAuth } from "~/composables/useAuth"
import { useLicenceStore } from "~/store/licenceStore"
import { sendMessageToSW } from "~/utilities/pwa"

export const useCacheStore = defineStore("cache", () => {
  const user = useAuth()
  const licence = useLicenceStore()
  const activeCache = ref<PWA.CacheStrategy>("CACHE_LOGGED_OUT")

  const cacheName = computed(() => {
    const authState = user.isAuthorized.value
      ? "LOGGED_IN"
      : "LOGGED_OUT"
    const activeLicence = licence.activeLicence
      ? `LICENCE_${licence.activeLicence.ean}`
      : "NO_ACTIVE_LICENCE"
    const name = `${authState}_${activeLicence}`
    if (
      name ===
      "LOGGED_IN_NO_ACTIVE_LICENCE_5470ec6da1bbbe6b7c9270183d443f08"
    ) {
      debugger
    }
    return name
  })

  watch(
    () => cacheName.value,
    async newCacheName => {
      sendMessageToSW({
        type: "CHANCE_ACTIVE_CACHE",
        strategy: newCacheName,
      })
      activeCache.value = newCacheName
    },
    { immediate: true },
  )

  return { activeCache }
})
