<script setup>
const personalization = usePersonalizationStore()
import { isFeatureEnabled } from "~/composables/useFeatureFlag"
</script>

<template>
  <div
    v-if="personalization?.settings"
    class="mb-6 h-fit w-full overflow-hidden rounded-lg bg-portal-indigo-400 p-6 text-white"
  >
    <div class="mb-4 flex items-center justify-start gap-3">
      <u-icon class="mb-1" name="gear" />

      <p class="text-xl lg:text-2xl">Feature Flags</p>
    </div>

    <u-checkbox
      v-model="isFeatureEnabled"
      label="Aktiver Feature Flags"
    />
  </div>
</template>

<route lang="yaml">
name: featureFlag
meta:
  title: featureFlag
  layout: basic
</route>
