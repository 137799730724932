<script setup lang="ts">
import { formatList } from "../../utilities/format"
import { baseSellingPoints } from "~/utilities/sellingPoints"

interface SellingPoint {
  icon: string
  text: string
}

const props = withDefaults(
  defineProps<{
    sellingPoints?: SellingPoint[]
    bgColor: string
    textColor: string
  }>(),
  {
    sellingPoints: () => [...baseSellingPoints],
  },
)

const combinedText = computed(() => {
  if (props.sellingPoints.length === 3) {
    const texts = props.sellingPoints.map(point =>
      point.text.toLowerCase(),
    )
    const formattedList = formatList(texts)
    return (
      formattedList.charAt(0).toUpperCase() + formattedList.slice(1)
    )
  }
  return null
})
</script>

<template>
  <div class="mt-6 flex w-full flex-col items-center">
    <div
      :class="[
        'gap-x-4 gap-y-5 md:gap-x-12',
        sellingPoints.length === 3
          ? 'flex justify-center'
          : 'grid grid-cols-2 gap-8 lg:flex lg:flex-wrap lg:justify-center',
      ]"
    >
      <div
        v-for="item in sellingPoints"
        :key="item.icon"
        class="flex flex-col items-center justify-center gap-2 lg:gap-4"
      >
        <UIcon
          :name="item.icon"
          size="lg"
          :class="[
            'rounded-full p-3 md:p-5 [&>svg]:h-[24px!important] md:[&>svg]:h-[36px!important]',
            bgColor || 'bg-[--theme-lighter]',
            textColor || 'text-[--theme-medium]',
          ]"
        />
        <h3
          v-if="sellingPoints.length !== 3"
          class="max-w-[200px] text-center font-national2 text-lg italic md:text-xl lg:text-2xl text-[--theme-darker]"
        >
          <b>{{ item.text }}</b>
        </h3>
      </div>
    </div>
    <h3
      v-if="sellingPoints.length === 3"
      class="mt-6 w-full text-center font-national2 text-lg italic md:text-xl lg:text-2xl"
    >
      <b>{{ combinedText }}</b>
    </h3>
  </div>
</template>
