// This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
// This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
// Call this function in your authentication promise handler or callback when your visitor and account id values are available

import type {
  RouteLocationNormalizedLoaded,
} from "vue-router"

// Please use Strings, Numbers, or Bools for value types.

interface PendoVisitorSchema {
  id: string // 'VISITOR-UNIQUE-ID'   // Required if user is logged in, default creates anonymous ID
  email: string // Recommended if using Pendo Feedback, or NPS Email
  full_name: string // Recommended if using Pendo Feedback
  role?: string // Optional
}

interface PendoAccountSchema {
  id: string // 'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
  name?: string // Optional
  is_paying: string // Recommended if using Pendo Feedback
  monthly_value: string // Recommended if using Pendo Feedback
  planLevel?: string // Optional
  planPrice?: string // Optional
  creationDate?: string // Optional
  // You can add any additional account level key-values here,
  // as long as it's not one of the above reserved names.
}

// If you are using Pendo Feedback, you must call this function
// with the visitor and account schemas.
// pendoAnalytics.identify(visitor, account)

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export default (route: RouteLocationNormalizedLoaded, uid?: string, ) => {
  const apiKey = import.meta.env.VITE_PENDO_API_KEY
  if (!apiKey) {
    console.info("Pendo API key not found")
    return
  }

  ;(function (p, e, n, d, o) {
    let v, w, x, y, z
    o = p[d] = p[d] || {}
    o._q = o._q || []
    // eslint-disable-next-line prefer-const
    v = [
      "initialize",
      "identify",
      "updateOptions",
      "pageLoad",
      "track",
    ]
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        // @ts-expect-error: Unreachable code error
        // eslint-disable-next-line prefer-rest-params
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"](
              [m].concat([].slice.call(arguments, 0)),
            )
          }
      })(v[w])
    // eslint-disable-next-line prefer-const
    y = e.createElement(n)
    y.async = !0
    y.defer = true
    y.src =
      "https://cdn.eu.pendo.io/agent/static/" + apiKey + "/pendo.js"
    // eslint-disable-next-line prefer-const
    z = e.getElementsByTagName(n)[0]
    z.parentNode.insertBefore(y, z)
  })(window, document, "script", "pendo")

  const win = window as any
  const pendoAnalytics = win.pendo

  const visitor = {
    id: uid,
    // email: user.email,
    // full_name: user.firstName + ' ' + user.lastName
  } as PendoVisitorSchema

  const firefoxVisitor = {
    id: "firefoxID",
  } as PendoVisitorSchema



  const getAccountID = () => {
    const product = useProductVariantsStore()
    if (product.activeVariant) {
      return (product.activeProduct?.productTitle+"-"+product.activeVariant?.shortVariantLabel).replaceAll(' ', '-').toLowerCase()
    }
    else if (product.activeProduct) {
      return product.activeProduct?.productTitle.replaceAll(' ', '-').toLowerCase()
    } else return "Unknown Product"
  }

  const account = {
    id: getAccountID(),
    is_paying: "false",
    monthly_value: "0",
  } as PendoAccountSchema
  if (navigator.userAgent.toLowerCase().includes("firefox")) {
    pendoAnalytics.initialize({
      firefoxVisitor,
      account,
    })
  } else {
    pendoAnalytics.initialize({
      visitor,
      account,
    })
  }
}
