import gql from "graphql-tag"

export default gql`
  query ($locationId: [Int]) {
    content {
      subjects(
        sortBy: _datePublished
        query: { ParentLocationId: $locationId }
        first: 50
      ) {
        edges {
          node {
            _location {
              hidden
              id
              priority
              parentLocationId
              content {
                ... on SubjectContent {
                  introPages {
                    template
                    colorTheme
                    richtext {
                      html5
                    }
                    image {
                      alternativeText
                      fileName
                      variation(identifier: fullwidth) {
                        uri
                      }
                      width
                      height
                      additionalData {
                        focalPointX
                        focalPointY
                      }
                    }
                    video {
                      id
                      title
                      image {
                        alternativeText
                        variation(identifier: fullwidth) {
                          uri
                        }
                      }
                      previewTitle
                      publishedBy
                      published
                      timestamps {
                        description
                        time
                      }
                      metadata
                      embedCode
                      transcription {
                        plaintext
                        html5
                      }
                    }
                  }
                  id
                  shortTitle
                  title
                  subjectPageTitlePosition
                  colorTag
                  chapterType
                  _url
                  _type {
                    identifier
                  }
                  image {
                    alternativeText
                    variation(identifier: fullwidth) {
                      uri
                    }
                    width
                    height
                    additionalData {
                      focalPointX
                      focalPointY
                    }
                  }
                  _info {
                    currentVersion {
                      versionNumber
                      creationDate {
                        timestamp
                      }
                      modificationDate {
                        timestamp
                      }
                    }
                    locations {
                      children {
                        totalCount
                      }
                    }
                    publishedDate {
                      timestamp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
