export const useArrayUtils = () => {
  /**
   * Unique simple array
   *
   * @param value
   * @param index
   * @param self
   */
  const unique = <T>(value: T, index: number, self: T[]) =>
    self.indexOf(value) === index

  /**
   * Filter an array by a unique key.
   *
   * Example:
   *
   * const array = [{ id: 1, name: 'foo' }, { id: 2, name: 'bar' }, { id: 1, name: 'baz' }]
   * array.filter(uniqueBy('id'))
   * => [{ id: 1, name: 'foo' }, { id: 2, name: 'bar' }]
   */
  const uniqueBy =
    <T>(key: keyof T) =>
    (value: T, index: number, self: T[]) =>
      self.findIndex(item => item[key] === value[key]) === index

  type Falsy = false | 0 | "" | null | undefined
  type Truthy<T> = Exclude<T, Falsy>
  /**
   * Filters values that a truthy
   *
   * @param value
   */
  const truthy = <T>(value: any): value is Truthy<T> => Boolean(value)

  /**
   * Creates an array of unique values that are included in all given arrays
   *
   * @param array1
   * @param array2
   */
  const intersect = <T>(array1: T[], array2: T[]) =>
    array1.filter(value => array2.includes(value))

  /**
   * Takes an Array<V>, and a grouping function, and returns a Map of the array
   * grouped by the grouping function.
   *
   * @param array
   * @param predicate
   */
  const groupBy = <T, K extends string>(
    array: T[],
    predicate: (value: T, index: number, array: T[]) => K,
  ) =>
    array.reduce(
      (acc, value, index, array) => {
        ;(acc[predicate(value, index, array)] ||= []).push(value)
        return acc
      },
      {} as Record<K, T[]>,
    )

  const empty = <T>(array: T[]) => array.length === 0

  /**
   * Compares two arrays for equality. Not deep.
   *
   * @param array1
   * @param array2
   */
  const equal = <T>(array1: T[], array2: T[]) =>
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])

  return {
    unique,
    uniqueBy,
    truthy,
    intersect,
    groupBy,
    empty,
    equal,
  }
}

export default useArrayUtils
