<script setup lang="ts">
import { useChapterCard } from "~/composables/useChapterCard"
import type { Subject } from "~/types/subject"

const props = defineProps({
  chapter: {
    type: Object as PropType<Subject>,
    required: true,
  },
})

const {
  cardGradientClass,
  imageType,
  calculateCardImageStyle,
  buildChapterUrl,
} = useChapterCard(props)
</script>

<template>
  <div class="relative aspect-[1/1] w-full lg:h-[511px]">
    <div
      class="absolute inset-0 z-10 -translate-x-2 translate-y-2 rounded-2xl bg-[--theme-lightest]"
    ></div>
    <div
      class="absolute inset-0 z-20 duration-200 shadow-md rounded-xl hover:-translate-y-1 hover:translate-x-1"
      :style="
        imageType === 'backgroundImage' ? calculateCardImageStyle : {}
      "
    >
      <router-link
        :id="`chapter-card-${chapter.id}`"
        data-pendo="chapter-card"
        :to="buildChapterUrl(chapter.href)"
        class="z-30 flex flex-col justify-between w-full h-full px-4 py-6 item-start rounded-xl lg:flex-row lg:items-end lg:px-11 lg:py-20"
        :class="[...cardGradientClass]"
      >
        <div
          class="flex items-center justify-center h-3/5 lg:h-full lg:w-1/2"
        >
          <img
            v-if="imageType == 'illustration'"
            :src="chapter.img.src"
            :alt="chapter.img.alt"
            class="object-contain w-full h-full"
          />
        </div>

        <UChapterCardContent :chapter="chapter" />
      </router-link>
    </div>
  </div>
</template>
