<script setup lang="ts">
import { formatDate } from "~/utilities/format"
import { SourceRelation } from "~/types/article"

defineProps({
  note: {
    type: Object as PropType<SourceRelation>,
    required: true,
  },
})

const subtleLinkClass =
  "text-[font:inherit] underline decoration-grey-60 decoration-2 underline-offset-2"
</script>

<template>
  <USidenote :note="note" title-class="text-[--color-red-dark]">
    <template #content>
      <div v-if="note.resource?.type === 'Website'">
        {{ note.resource.author }}
        ({{ note.resource.publishYear
        }}<template v-if="note.resource.publishDate"
          >, {{ formatDate(note.resource.publishDate) }}</template
        >). <span class="italic">{{ note.resource.title }}</span
        >.
        {{ note.resource.publisher }}
        <a
          :class="`${subtleLinkClass} whitespace-nowrap`"
          :href="note.resource.url"
          target="_blank"
        >
          {{ note.resource.url }}
          <u-icon
            class="inline-block"
            name="external-link"
            size="xs"
          />
        </a>
      </div>

      <div v-if="note.resource?.type === 'Article'">
        {{ note.resource.author }}
        ({{ note.resource.publishYear }}). {{ note.resource.title }}.
        <span class="italic">{{ note.resource.publisher }}</span
        >{{ note.appendText ? `, ${note.appendText}.` : "." }}
        <a
          v-if="note.resource.url"
          :class="`${subtleLinkClass} inline-block`"
          style="word-break: break-word"
          :href="note.resource.url"
          target="_blank"
        >
          {{ note.resource.url }}
          <u-icon
            class="inline-block"
            name="external-link"
            size="xs"
          />
        </a>
      </div>

      <div v-if="note.resource?.type === 'Book'">
        {{ note.resource.author }}
        ({{ note.resource.publishYear }}).
        <span class="italic">{{ note.resource.title }}</span
        ><template v-if="note.resource.edition">
          ({{ note.resource.edition }})</template
        >. {{ note.resource.publisher }}.
      </div>
    </template>
  </USidenote>
</template>
