export const baseSellingPoints = [
  { icon: "align-left", text: "Les korte tekster" },
  { icon: "medal", text: "Test deg selv" },
]

export const additionalSellingPoints = {
  video: { icon: "play", text: "Lær med video" },
  audio: { icon: "headphones", text: "Lytt til innholdet" },
}

export const videoProducts = [
  "exphil",
  "juss-for-barnevern",
  "organisasjonsatferd-og-ledelse",
  "organisasjon-og-ledelse",
  "psykologi-og-ledelse",
  "samtaler-med-barn",
]
