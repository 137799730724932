import type { BaseItem } from "~/models/Content/BaseItem"
import type { ContentImage } from "~/models/Content/ContentImage"
import type { ContentSvg } from "~/models/Content/ContentSvg"
import type { SortField, SortOrder } from "~/models/Content/Sort"
import type { Criterion } from "~/models/Search"
import type { BaseSearchResponse } from "~/models/Content/Response"
import { Subtree } from "~/models/Content/Subtree"
import { ContentType } from "~/models/Content/ContentType"
import arrayUtils from "~/utilities/arrayUtils"
import { useSearch } from "~/composables/useSearch"
import { mapContents } from "~/mapping/contentMapper"
import useSearchHelper from "~/composables/useSearchHelper"

/**
 * You should probably not extend this API.
 *
 * After the dimensional merge, where Content and ResourceItem are merged into the same
 * reality, there will be less and less need for a dedicated Content API. Instead, you
 * should probably look into extending the Resource and Section APIs.
 */
export default function useContentApi() {
  const { searchPath, emptyQuery, cardFields } = useSearchHelper()
  const { truthy } = arrayUtils()

  const getContent = async <T extends BaseItem>(
    criterions: Criterion,
    limit: number = 100,
    offset: number = 0,
    fields: string[] = [],
  ): Promise<T[]> => {
    const transformData = (response: BaseSearchResponse) => {
      if (!response.View) {
        return response
      }
      return mapContents(response.View.Result)
    }
    const { fetchResults, results, fetchResultsV2 } = useSearch<T[]>(
      searchPath,
      {
        transformData,
        debounceDuration: 0,
      },
    )

    try {
      if (isFeatureEnabled.value) {
        const resultsV2 = await fetchResultsV2(
          { ...emptyQuery, fields },
          criterions,
          limit,
          offset,
        )
        if (resultsV2.relations !== null) {
          console.log("fetchResultsV2", resultsV2)
        }
      }
    } catch (error) {
      console.error("Error fetching resultsV2:", error)
    }

    await fetchResults(
      { ...emptyQuery, fields },
      criterions,
      limit,
      offset,
    )
    return [...(results.value ?? [])]
  }

  const getChildren = async (
    parentLocationIds: number[],
    contentTypeIdentifiers: ContentType[] = [],
    sortField: SortField = "priority",
    sortOrder: SortOrder = "asc",
    extraFields: string[] = [],
  ): Promise<BaseItem[]> => {
    if (!parentLocationIds.filter(truthy).length) {
      console.warn("Missing parentLocationIds in getChildren")
      return []
    }

    return await getContent<BaseItem>(
      {
        parentLocationIdCriterion: parentLocationIds,
        contentTypeCriterion: contentTypeIdentifiers,
        sortField,
        sortOrder,
      },
      100,
      0,
      [...cardFields, ...extraFields],
    )
  }

  const getImageContent = async (
    contentId: number,
  ): Promise<ContentImage | undefined> => {
    return (
      await getContent<ContentImage>(
        {
          contentIdCriterion: [contentId],
          contentTypeCriterion: [ContentType.Image],
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          mainLocationCriterion: true,
        },
        1,
        0,
      )
    )[0]
  }

  const getSvgContent = async (
    contentId: number,
  ): Promise<ContentSvg | undefined> => {
    return (
      await getContent<ContentSvg>(
        {
          contentIdCriterion: [contentId],
          contentTypeCriterion: [ContentType.Svg],
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          mainLocationCriterion: true,
        },
        1,
        0,
      )
    )[0]
  }

  return {
    getContent: getContent,
    getImageContent: getImageContent,
    getSvgContent: getSvgContent,
    getChildren: getChildren,
  }
}
