/**
 * Color namer: https://colornamer.robertcooper.me/
 * Color gen: https://tailwind-color-palette.netlify.app/
 */

// TODO: remove this file, to do so you need to change files using this util to start using the css variables exposed by Tailwind

export default {
  white: "#ffffff",
  "u-contrast": "#F49F8B",
  platform: {
    paper: "#faf6f2",
    white: "#fdfdff",
    black: "#212427",
  },
  "portal-indigo": {
    50: "#e4e4ea",
    100: "#bbbdcd",
    200: "#8f92aa",
    300: "#676a89",
    400: "#4b4d73",
    500: "#30325f",
    600: "#2b2c57",
    700: "#24244d",
    800: "#1c1b41",
    900: "#120b2b",
    lightest: "#e4e4ea",
    lighter: "#bbbdcd",
    light: "#8f92aa",
    dark: "#676a89",
    darker: "#4b4d73",
    darkest: "#30325f",
  },
  "dark-blue": {
    10: "#e9f0fc",
    20: "#c7daf7",
    40: "#5275C7",
    60: "#204cb6",
    80: "#1a3985",
    100: "#142654",
    120: "#0f1838",
    lightest: "#e9f0fc",
    lighter: "#c7daf7",
    light: "#5275C7",
    medium: "#204CB6",
    dark: "#1a3985",
    darker: "#142654",
    darkest: "#0f1838",
    "translucent-dark": "#343F5E",
    "translucent-light": "#384A75",
  },
  "light-blue": {
    lightest: "#E5F0FF",
    lighter: "#B3D2FF",
    light: "#589CFF",
    medium: "#0469FF",
    dark: "#0052CE",
    darker: "#003990",
    darkest: "#002B65",
    "translucent-dark": "#244C84",
    "translucent-light": "#2458A6",
  },
  green: {
    10: "#e8f6eb",
    20: "#c8ecce",
    40: "#5FC16F",
    60: "#438f51",
    80: "#275c32",
    100: "#28362b",
    120: "#1a231b",
    lightest: "#e8f6eb",
    lighter: "#c8ecce",
    light: "#5FC16F",
    medium: "#438f51",
    dark: "#275c32",
    darker: "#28362b",
    darkest: "#1a231b",
    "translucent-dark": "#3D4B3F",
    "translucent-light": "#485A4C",
  },
  teal: {
    10: "#EDFCFB",
    20: "#C1F0EF",
    40: "#77dbd8", // Not used in semantic color theming
    60: "#2B95A1",
    80: "#216E76",
    100: "#00525C",
    120: "#003E46",
    lightest: "#EDFCFB",
    lighter: "#C1F0EF",
    light: "#77dbd8",
    medium: "#2B95A1",
    dark: "#216E76",
    darker: "#00525C",
    darkest: "#003E46",
    "translucent-dark": "#276268",
    "translucent-light": "#277279",
  },
  yellow: {
    10: "#fefae4",
    20: "#fff6bc",
    40: "#fae86f",
    60: "#ffdc0d",
    80: "#ecba0c",
    100: "#9f7008",
    120: "#362703",
    lightest: "#fefae4",
    lighter: "#fff6bc",
    light: "#FAE86F",
    medium: "#ffdc0d",
    dark: "#ecba0c",
    darker: "#9f7008",
    darkest: "#362703",
  },
  purple: {
    10: "#f9eff7",
    20: "#eacae4",
    40: "#CE78C5",
    60: "#af3eaa",
    80: "#813079",
    100: "#4c1949",
    120: "#311030",
    lightest: "#f9eff7",
    lighter: "#eacae4",
    light: "#CE78C5",
    medium: "#af3eaa",
    dark: "#813079",
    darker: "#4c1949",
    darkest: "#311030",
    "translucent-dark": "#563554",
    "translucent-light": "#6C3C68",
  },
  red: {
    10: "#fbf0ef",
    20: "#f7dcd7",
    40: "#f49f8b",
    60: "#ec6343",
    80: "#bd2b12",
    100: "#7a1d0e",
    120: "#380e06",
    lightest: "#fbf0ef",
    lighter: "#f7dcd7",
    light: "#f49f8b",
    medium: "#ec6343",
    dark: "#bd2b12",
    darker: "#7a1d0e",
    darkest: "#380e06",
    "translucent-dark": "#5E3730",
    "translucent-light": "#934336",
  },
  grey: {
    10: "#f6f4f4",
    20: "#ebe7e3",
    40: "#BEB9B2",
    60: "#918b81",
    80: "#645d4f",
    100: "#372f1e",
    120: "#1e1d1b",
    lightest: "#f6f4f4",
    lighter: "#ebe7e3",
    light: "#BEB9B2",
    medium: "#918b81",
    dark: "#645d4f",
    darker: "#372f1e",
    darkest: "#1e1d1b",
  },
  kunne: {
    lightest: "var(--color-platform-paper)",
    lighter: "#D9D2F4",
    light: "#F49F8B",
    medium: "#523F92",
    dark: "#373479",
    darker: "#221F51",
    darkest: "#1B1042",
    "translucent-dark": "#EC6343",
    "translucent-light": "#F49F8B",
  },
}
