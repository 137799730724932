import { set as setMetadata } from "~/api/setUserMetadata"
import { get as getMetadata } from "~/api/getUserMetadata"
import * as Sentry from "@sentry/vue"
import axios from "axios"

export interface DeviceSession {
  deviceId: string
  lastActive: string
  deviceInfo: string
}

const POLLING_INTERVAL = 3 * 60 * 1000 // 3 minutes
const SESSION_EXPIRY = 6 * 24 * 60 * 60 * 1000 // 6 days

export function useDeviceLimiter() {
  const currentDevice = ref<DeviceSession | null>(null)
  const hasActiveDeviceConflict = ref(false)
  let deviceCheckInterval: ReturnType<typeof setInterval> | null =
    null

  function getDeviceIdentifier(): string {
    const components = [
      navigator.userAgent,
      screen.width,
      screen.height,
      navigator.language,
      navigator.hardwareConcurrency,
      // Add a random component that persists in localStorage
      localStorage.getItem("deviceKey") ||
        (() => {
          const newKey = Math.random().toString(36).substring(2)
          localStorage.setItem("deviceKey", newKey)
          return newKey
        })(),
    ]

    return components.join("|")
  }

  function getDeviceInfo(): string {
    return `${navigator.userAgent} - ${window.location.hostname}`
  }

  function isDeviceSessionExpired(session: DeviceSession): boolean {
    const lastActiveTime = new Date(session.lastActive).getTime()
    const now = new Date().getTime()
    return now - lastActiveTime > SESSION_EXPIRY
  }

  async function checkActiveDevice(): Promise<
    DeviceSession | undefined
  > {
    try {
      const deviceSession = (await getMetadata("active-device")) as
        | DeviceSession
        | undefined

      if (!deviceSession) return undefined

      if (isDeviceSessionExpired(deviceSession)) {
        currentDevice.value = null
        hasActiveDeviceConflict.value = false
        return undefined
      }

      const thisDeviceId = getDeviceIdentifier()
      const isConflicting = deviceSession.deviceId !== thisDeviceId

      if (isConflicting) {
        const win = window as any
        const pendoAnalytics = win.pendo
        pendoAnalytics.track("Device Limiter Conflict", {
          deviceId: deviceSession.deviceId,
          currentDeviceId: thisDeviceId,
        })
      }
      hasActiveDeviceConflict.value = isConflicting
      currentDevice.value = deviceSession

      return isConflicting ? deviceSession : undefined
    } catch (error) {
      const isUnauthorized =
        axios.isAxiosError(error) && error.response?.status === 401

      if (!isUnauthorized) {
        Sentry.captureException(error)
      }

      console.error("Error checking active device:", error)
      return undefined
    }
  }

  async function registerDevice(userId: string): Promise<void> {
    try {
      const deviceSession: DeviceSession = {
        deviceId: getDeviceIdentifier(),
        lastActive: new Date().toISOString(),
        deviceInfo: getDeviceInfo(),
      }
      await setMetadata("active-device", deviceSession, userId)
      currentDevice.value = deviceSession
      hasActiveDeviceConflict.value = false
    } catch (error) {
      const isUnauthorized =
        axios.isAxiosError(error) && error.response?.status === 401

      if (!isUnauthorized) {
        Sentry.captureException(error)
      }

      console.error("Error registering device:", error)
      // If metadata fails, still allow access but don't update state
      currentDevice.value = null
      hasActiveDeviceConflict.value = false
    }
  }

  async function startDevicePolling() {
    if (deviceCheckInterval) {
      clearInterval(deviceCheckInterval)
    }

    deviceCheckInterval = setInterval(async () => {
      await checkActiveDevice()
    }, POLLING_INTERVAL)
  }

  async function initializeDeviceLimiter(
    userId: string,
  ): Promise<void> {
    try {
      await startDevicePolling()
      const existingDevice = await checkActiveDevice()

      if (!existingDevice) {
        await registerDevice(userId)
      }
    } catch (error) {
      const isUnauthorized =
        axios.isAxiosError(error) && error.response?.status === 401

      if (!isUnauthorized) {
        Sentry.captureException(error)
      }

      console.error("Error initializing device limiter:", error)
      // If anything fails, don't block acces
      currentDevice.value = null
      hasActiveDeviceConflict.value = false
    }
  }

  async function terminateDeviceSession(
    userId?: string,
  ): Promise<void> {
    try {
      await setMetadata("active-device", null, userId)
      currentDevice.value = null
      hasActiveDeviceConflict.value = false
      if (deviceCheckInterval) {
        clearInterval(deviceCheckInterval)
        deviceCheckInterval = null
      }
    } catch (error) {
      const isUnauthorized =
        axios.isAxiosError(error) && error.response?.status === 401
      if (!isUnauthorized) {
        Sentry.captureException(error)
      }
      console.error("Error terminating device session:", error)
    }
  }

  return {
    currentDevice,
    hasActiveDeviceConflict,
    initializeDeviceLimiter,
    checkActiveDevice,
    registerDevice,
    terminateDeviceSession,
  }
}
