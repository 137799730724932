<script setup>
const backgroundColor = useBackgroundColor()
backgroundColor.value = "var(--theme-lightest)"

const products = useProductVariantsStore()
</script>

<template>
  <div
    class="flex min-h-[100dvh] w-full flex-col items-center justify-between"
  >
    <div
      class="flex w-[90vw] flex-col items-center justify-center md:w-[80vw]"
    >
      <UHeader :product="products.activeProduct" />
      <UArticleLoadingScreen v-if="themeColor" />
      <main
        v-else
        class="flex h-full max-w-[100em] items-center justify-center <sm:max-w-[90vw]"
      >
        <router-view />
      </main>
    </div>
    <UMarketingFooter />
  </div>
</template>
