import type { Article } from "~/types/article"
import type { Subject } from "~/types/subject"
import type { Folder } from "~/types/folder"
import { acceptHMRUpdate, defineStore } from "pinia"
import getDataFormat from "~/api/getSubjects"
import getArticlesDataFormat from "~/api/getArticles"
import { getRestrictedArticles } from "~/api/getLockedArticle"
import { FOLDER_REGEX } from "~/utilities/constants"
import { NProgress } from "~/utilities/composables"

import {
  formatRestrictedArticles,
  formatSubjectPage,
} from "~/utilities/articles"
import {
  findNextValidSubject,
  findPreviousValidSubject,
} from "~/utilities/subjectUtils"

export const useProductContentStore = defineStore(
  "productContent",
  () => {
    /** State */
    const needsSorting = ref(true)
    const allSubjects = ref<Subject[]>([])
    const allArticles = ref<Article[]>([])
    const allFolders = ref<Folder[]>([])

    const productStore = useProductVariantsStore()
    const articleSubjectHref = ref<string | undefined>(undefined)
    const articleHref = ref<string | undefined>(undefined)
    const nextSubject = ref<Subject | undefined>(undefined)
    const previousSubject = ref<Subject | undefined>(undefined)
    const activeSubject = computed(() => {
      const res = allSubjects.value.find(
        s =>
          s.href ===
          `/${productStore.product}-${productStore.variant}/${articleSubjectHref.value}`,
      )

      return res
    })

    const nextArticle = ref<Article | undefined>(undefined)
    const previousArticle = ref<Article | undefined>(undefined)
    const activeArticle = computed(() => {
      if (allArticles.value.length === 0) return null
      const res = allArticles.value.find(
        a =>
          a.href ===
          `/${productStore.product}-${productStore.variant}/${articleSubjectHref.value}/${articleHref.value}`,
      )
      return res
    })

    /** Getters */
    const subjects: ComputedRef<Subject[]> = computed(() => {
      const folderParentIds = folders.value.map(f => f.id)

      return allSubjects.value.filter(s => {
        if (folderParentIds.includes(s.parentLocationId)) {
          return true
        } else if (
          s.parentLocationId ===
          productStore.activeVariant?.locationId
        ) {
          return true
        } else return false
      })
    })
    const articles: ComputedRef<Article[]> = computed(() => {
      const res = allArticles.value.filter(
        a => a.parentLocationId === activeSubject.value?.id,
      )
      return res
    })
    const folders: ComputedRef<Folder[]> = computed(() => {
      return allFolders.value
        .filter(
          f =>
            f.parentLocationId ===
            productStore.activeVariant?.locationId,
        )
        .sort((a, b) => a.priority - b.priority)
    })

    /** Getters */
    const subjectNav = computed(() =>
      subjects.value
        .map(i => {
          return {
            id: i.id,
            parentLocationId: i.parentLocationId,
            text: i.title,
            hrefRaw: i.href,
            href: i.href.replace(FOLDER_REGEX, ""),
            priority: i.priority,
            hidden: i.hidden,
            totalChildren: i.totalChildren,
            type: i.type,
          }
        })
        .sort((a, b) => a.priority - b.priority),
    )

    const articleNav = computed(() =>
      articles.value.map(i => {
        return {
          id: i.id,
          parentLocationId: i.parentLocationId,
          text: i.title,
          hrefRaw: i.href,
          href: i.href.replace(FOLDER_REGEX, ""),
          priority: i.priority,
          hidden: i.hidden,
          restricted: i.restricted || false,
        }
      }),
    )

    /** Fetch subjects and folders */
    const hydratedProductLocationsIds: number[] = []
    const hydrationInProgress = ref<boolean>(false)
    const noSubjectsFound = ref<boolean>(false)
    watchEffect(async () => {
      if (!productStore.activeVariant || hydrationInProgress.value)
        return
      if (
        hydratedProductLocationsIds.includes(
          productStore.activeVariant.locationId,
        )
      )
        return

      NProgress.start()
      hydrationInProgress.value = true
      try {
        const locationId = productStore.activeVariant.locationId
        hydratedProductLocationsIds.push(locationId)
        const data = await getDataFormat(locationId)

        if (data.subjects.length === 0) noSubjectsFound.value = true

        allFolders.value = [...allFolders.value, ...data.folders]
        allSubjects.value = [...allSubjects.value, ...data.subjects]
        NProgress.done()
      } catch (error) {
        NProgress.done()
        throw error
      } finally {
        hydrationInProgress.value = false
        needsSorting.value = true
      }
    })

    /** Fetch articles */
    const hydratedSubjectLocationsIds: number[] = []
    const hydrateArticlesInProgress = ref<boolean>(false)
    watchEffect(async () => {
      const beforeNowNext = async (subject: Subject | undefined) => {
        if (subject === undefined) return []

        NProgress.start()
        hydrateArticlesInProgress.value = true
        try {
          // if totalChildren is 0, it's a restricted subject
          // so we have to format the articles in restricted format
          if (subject.totalChildren === 0) {
            const restrictedArticlesRaw = await getRestrictedArticles(
              subject.id,
            )

            const restrictedArticles = formatRestrictedArticles(
              restrictedArticlesRaw,
              subject?.id,
            ) as Article[]
            NProgress.done()
            return restrictedArticles
          }

          const items = await getArticlesDataFormat(subject.id)
          const subjectPage = formatSubjectPage(subject)
          if (subjectPage) items.unshift(subjectPage)

          NProgress.done()
          return items
        } catch (error) {
          console.error(error)
          throw error
        } finally {
          hydrateArticlesInProgress.value = false
          NProgress.done()
        }
      }

      // Get articles for the active subject
      if (
        activeSubject.value &&
        !hydratedSubjectLocationsIds.includes(activeSubject.value.id)
      ) {
        hydratedSubjectLocationsIds.push(activeSubject.value.id)
        const nowRes = await beforeNowNext(activeSubject.value)
        allArticles.value = [...allArticles.value, ...nowRes]

        needsSorting.value = true
      }

      // Get articles for the previous subject
      if (
        previousSubject.value &&
        !hydratedSubjectLocationsIds.includes(
          previousSubject.value.id,
        )
      ) {
        hydratedSubjectLocationsIds.push(previousSubject.value.id)
        const beforeeRes = await beforeNowNext(previousSubject.value)
        allArticles.value = [...allArticles.value, ...beforeeRes]
        needsSorting.value = true
      }

      // Get articles for the next subject
      if (
        nextSubject.value &&
        !hydratedSubjectLocationsIds.includes(nextSubject.value.id)
      ) {
        hydratedSubjectLocationsIds.push(nextSubject.value.id)
        const nextRes = await beforeNowNext(nextSubject.value)
        allArticles.value = [...allArticles.value, ...nextRes]
        needsSorting.value = true
      }
    })

    //** Create a frontentHref for each article */
    watchEffect(() => {
      for (const a of allArticles.value) {
        if (a.frontendHref || !a.href) continue

        allArticles.value.map(a => {
          const urlFraction = a.href.split("/")
          const subjectUrlFraction = urlFraction[2]
          const articleUrlFraction = urlFraction[3]

          a.frontendHref = `/produkter/${productStore.activeProduct?.productKey}/${productStore.activeVariant?.variantKey}/${subjectUrlFraction}/${articleUrlFraction}`
        })
      }
    })

    /** Sort allArticles first based on subjects priority and then article priority */
    watchEffect(() => {
      if (!needsSorting.value) {
        return
      }

      if (allFolders.value.length !== 0) {
        const sortedFolders = [...folders.value].sort(
          (a, b) => a.priority - b.priority,
        )

        const sortedSubjects: Subject[] = []
        for (const folder of sortedFolders) {
          let subjects = allSubjects.value.filter(
            subj => subj.parentLocationId === folder.id,
          )
          subjects = subjects.sort((a, b) => a.priority - b.priority)
          sortedSubjects.push(...subjects)
        }

        allFolders.value = sortedFolders
        allSubjects.value = sortedSubjects
      } else {
        allSubjects.value = [...allSubjects.value].sort(
          (a, b) => a.priority - b.priority,
        )
      }

      const sortedArticles: Article[] = []
      for (const subject of allSubjects.value) {
        let articles = allArticles.value.filter(
          article => article.parentLocationId === subject.id,
        )
        articles = articles.sort((a, b) => {
          // Intro slides should always be the first article in a product
          if (
            a.template === "USlidesArticle" &&
            b.template !== "USlidesArticle"
          ) {
            return -1
          }
          if (
            a.template !== "USlidesArticle" &&
            b.template === "USlidesArticle"
          ) {
            return 1
          }

          return a.priority - b.priority
        })
        sortedArticles.push(...articles)
      }
      allArticles.value = sortedArticles

      needsSorting.value = false
    })

    /** Fetch next and previous subjects */
    watchEffect(() => {
      if (!activeSubject.value) {
        previousSubject.value = undefined
        nextSubject.value = undefined
        return
      }

      const currentSubjectIndex = allSubjects.value.findIndex(
        s => s.id === activeSubject.value?.id,
      )

      // Find previous subject
      previousSubject.value = findPreviousValidSubject(
        subjects.value,
        currentSubjectIndex - 1,
      )

      // Find next subject
      nextSubject.value = findNextValidSubject(
        subjects.value,
        currentSubjectIndex + 1,
      )
    })

    /** Fetch next and previous articles */
    watchEffect(() => {
      if (!activeArticle.value) {
        previousArticle.value = undefined
        nextArticle.value = undefined
      }

      const lastIndex = allArticles.value.length - 1
      const currentIndex = allArticles.value.findIndex(
        a => a.id === activeArticle.value?.id,
      )
      if (currentIndex === 0) {
        previousArticle.value = undefined
        nextArticle.value = allArticles.value[currentIndex + 1]
      } else if (
        currentIndex === lastIndex &&
        articles.value.length > 1
      ) {
        previousArticle.value = allArticles.value[currentIndex - 1]
        nextArticle.value = undefined
      } else {
        previousArticle.value = allArticles.value[currentIndex - 1]
        nextArticle.value = allArticles.value[currentIndex + 1]
      }
    })

    return {
      subjects,
      articles,
      activeArticle,
      nextArticle,
      previousArticle,
      folders,
      subjectNav,
      articleNav,
      articleSubjectHref,
      activeSubject,
      nextSubject,
      previousSubject,
      noSubjectsFound,
      articleHref,
      allArticles,
      allSubjects,
      hydrateArticlesInProgress,
      hydrationInProgress,
    }
  },
)

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useDataStore, import.meta.hot),
  )
