import themeColors from "virtual:theme-colors"
import { isColorDark } from "./colorUtils"

export default (bgClass: string): boolean => {
  let isDark = false
  // Check first if class has a number postfix
  const numberPostfix = bgClass.split("-").splice(-1).toString()
  const isNumber = !isNaN(parseInt(numberPostfix))
  const nameLevels = bgClass.split("-")
  const getClassName = (arr: string[]) =>
    arr.length > 2 ? arr.splice(0, 2).join("-") : arr[0]
  const name = getClassName(nameLevels)

  const group = themeColors[name]

  if (!group) return false

  // If postfix of the classname is a number, the class represents a deep nested object
  if (isNumber) {
    const recursivelyFind = (children: [string, string][]) => {
      children.map(([key, value]) => {
        if (numberPostfix === key) {
          isDark = isColorDark(value) === "dark" ? true : false
        } else if (themeColors[name][key]) {
          recursivelyFind(Object.entries(themeColors[name][key]))
        }
      })
    }

    if (!group) return false

    recursivelyFind(Object.entries(group))
  }
  // If not, the group variable represents a hex code.
  // We measure the color's brightness to determine if it's dark or light
  if (typeof group === "string")
    isDark = isColorDark(group) === "dark" ? true : false

  return isDark
}
