const backgroundColor = ref<
  | "var(--theme-lightest)"
  | "var(--theme-dark)"
  | "var(--theme-darkest)"
  | "var(--color-platform-paper)"
>("var(--color-platform-paper)")

export function useBackgroundColor() {
  return backgroundColor
}
