const currencyFormater = Intl.NumberFormat("nb", {
  style: "currency",
  currency: "NOK",
  currencyDisplay: "narrowSymbol",
})

const dateFormater = Intl.DateTimeFormat("nb", {
  month: "long",
  day: "numeric",
})

export function formatCurrency(amount: number) {
  return currencyFormater
    .formatToParts(amount)
    .filter(({ type }) => type !== "currency")
    .map(({ type, value }) =>
      type === "fraction" && value === "00" ? "-" : value,
    )
    .join("")
}

export function formatDate(
  date: Date,
  options?: Intl.DateTimeFormatOptions,
) {
  if (options !== null) {
    return Intl.DateTimeFormat("nb", options).format(date)
  }

  // If no options are provided, reuse the default formatter to prevent creating new formatters.
  return dateFormater.format(date)
}

export function formatYearRange(from: number, to?: number | null) {
  const { abs } = Math
  const beforeEra = "fvt."
  const afterEra = "evt."

  if (to === null || to === undefined) {
    return from < 0 ? `${abs(from)} ${beforeEra}` : `${from}`
  }

  if (to < 0 && from < 0) {
    return `${abs(from)}-${abs(to)} ${beforeEra}`
  }

  if (to < 0 && from >= 0) {
    return `${abs(from)} ${beforeEra} - ${to} ${afterEra}`
  }

  return `${from}-${to}`
}

export const sanitizeCurriculum = (text: string) => {
  return text
    .replaceAll("og", ",")
    .replaceAll("eller", ",")
    .replaceAll(" ", "")
}

export const formatList = (items: string[]): string => {
  const formatter = new Intl.ListFormat("no", {
    style: "long",
    type: "conjunction",
  })

  return formatter.format(items)
}

type ParsedResult = {
  shortNamesAndCourseCodes: [string, string][] // Array of tuples [Institution, CourseCode]
  vanitySentence: string // Optional vanity sentence
}

/**
 * Parses the provided `pensumFor` string to extract institution names, course codes, and/or a vanity sentence.
 * The function can handle various input formats, including just a vanity sentence, just institution(s) with optional course code(s),
 * or a combination of both.
 *
 * @param {string} pensumFor - A string that can be in one of the following formats:
 *   - "[vanitySentence]"
 *   - "Institution1(CourseCode1) og Institution2(CourseCode2)"
 *   - "[vanitySentence] Institution1(CourseCode1) og Institution2(CourseCode2)"
 *
 * @returns {ParsedResult} An object containing the vanity sentence (if provided) and an array of tuples
 * where each tuple contains an institution and an optional course code.
 *
 * @example
 * // Example with multiple institutions, course codes, and a vanity sentence
 * const result1 = parseInstitutionNameAndCode("[Bachelorstudier i økonomi] UiO(EXPHIL03) og UiA(EX-100)");
 * console.log(result1);
 * // {
 * //   shortNamesAndCourseCodes: [ ['UiO', 'EXPHIL03'], ['UiA', 'EX-100'] ],
 * //   vanitySentence: 'Bachelorstudier i økonomi'
 * // }
 *
 * @example
 * // Example with a single institution and no course code
 * const result2 = parseInstitutionNameAndCode("NTNU");
 * console.log(result2);
 * // {
 * //   shortNamesAndCourseCodes: [ ['NTNU', ''] ],
 * //   vanitySentence: ''
 * // }
 *
 * @example
 * // Example when `pensumFor` is empty
 * const result3 = parseInstitutionNameAndCode("");
 * console.log(result3);
 * // {
 * //   shortNamesAndCourseCodes: [],
 * //   vanitySentence: ''
 * // }
 *
 * @example
 * // Example with only a vanity sentence
 * const result4 = parseInstitutionNameAndCode("[Bachelorstudier i økonomi]");
 * console.log(result4);
 * // {
 * //   shortNamesAndCourseCodes: [],
 * //   vanitySentence: 'Bachelorstudier i økonomi'
 * // }
 */
export const parseInstitutionNameAndCode = (
  pensumFor: string,
): ParsedResult => {
  let vanitySentence = ""
  let pensumData = pensumFor.trim()

  // Check if there's a vanity sentence wrapped in []
  const vanityMatch = pensumData.match(/^\[(.*?)\]/)
  if (vanityMatch) {
    vanitySentence = vanityMatch[1].trim()
    pensumData = pensumData.replace(vanityMatch[0], "").trim()
  }

  // Parse the remaining part for institution names and course codes
  const shortNamesAndCourseCodes = pensumData
    ? pensumData.split(" og ").map(parseCourseString)
    : []

  return {
    shortNamesAndCourseCodes,
    vanitySentence,
  }
}

/**
 * Parses a single course string and extracts the institution and course code (if available).
 *
 * @param {string} courseString - A string representing an institution and an optional course code, like "UiO(EXPHIL03)" or "NTNU".
 *
 * @returns {[string, string]} A tuple containing the institution and the optional course code.
 */
export const parseCourseString = (
  courseString: string,
): [string, string] => {
  const [institution, courseCode] = courseString.includes("(")
    ? courseString.split(/[()]/).map(s => s.trim())
    : [courseString.trim(), ""]
  return [institution, courseCode]
}
