

import __layout_0 from '/builds/aschehoug/universitetsforlaget/kunne-spa/src/layouts/default.vue'
export const layouts = {
'404': () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/layouts/404.vue'),
'article': () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/layouts/article.vue'),
'basic': () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/layouts/basic.vue'),
'default': __layout_0,
'marketing': () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/layouts/marketing.vue'),
'navigation': () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/layouts/navigation.vue'),
'product': () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/layouts/product.vue'),
'productPage': () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/layouts/productPage.vue'),
'subject': () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/layouts/subject.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
