import colors from "./colors"

// TODO: Check if this is needed or if there is a workaround?

/*
function range(size: number, startAt = 1) {
  return Array.from(Array(size).keys()).map(i => i + startAt)
}

const staticUtilities = [
  'cursor-pointer'
]
const mediaQueries = [
  '',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl'
]

const positions = [
  'start', 'end', 'center', 'baseline',
  'stretch'
]

const flow = [
  'col', 'row', 'row-dense', 'col-dense'
]

const classesWithNumberPostFix = [
  'border-t', 'border-b', 'border-r',
  'border-l', 'p', 'px', 'py', 'pt',
  'pb', 'pl', 'pr', 'm', 'mx', 'my',
  'mt', 'mb', 'ml', 'mr', 'grid-cols',
  'gap', 'col-span', 'grid-rows',
  'row-start', 'row-end', 'w', 'h'
].map((className) =>
  range(20).map((num) => mediaQueries.map((size, key) =>
    `${key === 0 ? '' : `${size}:`}${className}-${num}`
  ))).toString().replace(/,/g, ' ')

const classesWithStringPostFix = [
  flow.map((i) => `grid-flow-${i}`).join(' '),
  'justify-start',
  'justify-center',
  'justify-end',
  'container',
  'mx-auto',
  positions.map((i) => `items-${i}`).join(' ')
].map((className) =>
  mediaQueries.map((size, key) =>
    `${key === 0 ? '' : `${size}:`}${className}`
  )).toString().replace(/,/g, ' ')


const classesWithColorsPostFix = allColors.map((i) => {
  return [
    `hover:bg-${i}`,
    `hover:text-${i}`,
    `hover:border-${i}`,
    `bg-${i}`,
    `text-${i}`,
    `border-${i}`
  ].join(' ')
}).join(' ')

// Static CSS classes that only needs media query prefix
const mediaQueryClasses = [
  'bg-cover',
  'bg-contain'
].map((className) => {
  return mediaQueries.map((size) => {
    return size + ':' + className
  }).join(' ')
}).join(' ')
 */

const allColors = [] as string[]
Object.entries(colors).map(i => {
  if (typeof i[1] !== "object") {
    i.map(el => {
      if (!String(el).includes("#")) {
        allColors.push(String(el))
      }
    })
  } else {
    i.map((el, key, array) => {
      if (typeof el === "object") {
        const name = array[key - 1]

        Object.keys(el).map(level => {
          allColors.push(`${name}-${level}`)
        })
      }
    })
  }
})

const classesWithColorPrefix = allColors
  .map(i => {
    return [
      `hover:bg-${i}`,
      `hover:text-${i}`,
      `hover:border-${i}`,
      `bg-${i}`,
      `text-${i}`,
      `border-${i}`,
      `fill-${i}`,
      `stroke-${i}`,
      `after:from-${i}`,
    ].join(" ")
  })
  .join(" ")

const afterWidthClasses = Array.from({ length: 101 }, (_, key) => {
  return `after:w-[${key}%]`
}).join(" ")

const widthClasses = Array.from({ length: 20 }, (_, key) => {
  return `w-${key}`
}).join(" ")

const staticClasses = [
  "before:content-[''] before:z-0 before:w-full before:h-[0.1rem] before:bg-gray-600 before:absolute before:left-0 before:bottom-0 before:rounded-full",
  "after:w-full after:z-10 after:left-0 after:bottom-0 after:absolute after:h-[0.1rem] after:content-[''] after:hover:bg-on-primary after:duration-300 after:rounded-full after:bg-on-primary after:bg-white",
  "font-thin",
  "font-extralight",
  "font-light",
  "font-normal",
  "font-medium",
  "font-semibold",
  "font-bold",
  "font-extrabold",
  "font-black",
].join(" ")

const bgClasses = Object.entries(colors)
  .map(([key, value]) => {
    const array = [] as string[]

    if (typeof value === "object") {
      Object.keys(Object(value)).map(i =>
        array.push("bg-" + key + "-" + i),
      )
    } else {
      array.push("bg-" + key)
    }

    return array.join(" ")
  })
  .join(" ")

const gridClasses = Array.from({ length: 20 }, (_, i) => {
  const start = `col-start-${i + 1}`
  const end = `col-end-${i + 1}`
  const media = [
    "<md",
    "md",
    "lg",
    "2xl",
    "@<md",
    "@md",
    "@lg",
    "@2xl",
  ]
  return media.map(m => `${m}:${start} ${m}:${end}`).join(" ")
}).join(" ")

export default [
  /* classesWithNumberPostFix,
  classesWithStringPostFix,
  classesWithColorsPostFix,
  staticUtilities,
  staticClasses,
  mediaQueryClasses */
  classesWithColorPrefix,
  bgClasses,
  widthClasses,
  afterWidthClasses,
  staticClasses,
  gridClasses,
]
