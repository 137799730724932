import { useProductVariantsStore } from "~/store/productVariantsStore"

export type OfflineAccess = "not-available" | "downloading" | "ready"

export const statusMessages: Record<
  OfflineAccess,
  { title: string; description: string }
> = {
  "not-available": {
    title: "Last ned (200MB)",
    description:
      "Last innhold og få tilgang på tekst og bilder offline.",
  },
  downloading: {
    title: "Laster ned...",
    description: "Nedlastingen er i gang.",
  },
  ready: {
    title: "Lastet ned",
    description:
      "Kunne (tekst og bilder) er nå tilgjengelig offline.",
  },
}

export const downloadProgress = ref({
  subjectsDownloaded: 0,
  totalSubjects: 0,
  imageDownloadedStatus: "not-started" as
    | "not-started"
    | "started"
    | "finished",
  videoDownloadedStatus: "not-started" as
    | "not-started"
    | "started"
    | "finished",
  articlesDownloadedStatus: "not-started" as
    | "not-started"
    | "started"
    | "finished",
  imagesDownloaded: 0,
  videosDownloaded: 0,
  totalImages: 0,
  totalVideos: 0,
  status:
    (localStorage.getItem("offline-access") as OfflineAccess) ??
    "not-available",
})

watch(
  downloadProgress,
  newValue => {
    localStorage.setItem("offline-access", newValue.status)
  },
  { deep: true },
)

export function cacheProductForOfflineUse(
  productName: string,
  variant: string,
): Promise<void> {
  throw new Error("Not implemented")
}

const offlineAccess =
  (localStorage.getItem("offline-access") as OfflineAccess) ??
  "not-available"
if (offlineAccess === "downloading") {
  watchEffect(() => {
    setTimeout(async () => {
      const store = useProductVariantsStore()
      downloadProgress.value.status = "not-available"
      for (const productKey in store.groupedProductVariants) {
        const product = store.groupedProductVariants[productKey]
        for (const variant of product.variants) {
          if (!variant.userHasLicence) continue
          await cacheProductForOfflineUse(
            productKey,
            variant.variantKey,
          )
        }
      }
    }, 5000)
  })
}
