export default function setupNotFoundWatcher() {
  const productContent = useProductContentStore()
  const product = useProductVariantsStore()
  const router = useRouter()

  watch(
    () => productContent.noSubjectsFound,
    newValue => {
      if (newValue === true && product.variant) {
        router.replace({
          path: "/404",
          query: {
            r: "Vi fant ikke noe innhold i produktet du prøvde å nå",
          },
        })
      }
    },
    { immediate: true },
  )
}
