import { acceptHMRUpdate, defineStore } from "pinia"
import { getGroupedProductVariants } from "~/utilities/grouped-product-variants"
import {
  ProductsMap,
  ProductVariants,
  ProductsList,
  ProductInformation,
} from "~/models/Content/ContentProducts"
import { getProductData } from "~/mapping/payment"
import type { EcommerceProduct } from "~/types/productData.d"
import {
  ProductPageList,
  getProductPageList as getMarketingData,
} from "~/api/getProductPageList"
import useContent from "~/api/getContent"

const PRODUCTS_LOCATION_ID = import.meta.env
  .VITE_CMS_PRODUCTS_LOCATION_ID as string | "0"

export const useProductVariantsStore = defineStore("products", () => {
  /** State */
  const licence = useLicenceStore()
  const productVariantsRaw = ref<ProductVariants[] | null>(null)
  const productVariants = ref<ProductInformation[]>([])
  const productsMap = ref<ProductsMap | undefined>(undefined)
  const productsList = ref<ProductsList[]>([])

  const product = ref<string | undefined>(undefined)
  const variant = ref<string | undefined>(undefined)
  const activeVariant = ref<ProductInformation | undefined>(undefined)
  const activeProduct = ref<ProductsList | undefined>(undefined)
  const eCommerce = ref<EcommerceProduct[]>([])
  const marketingData = ref<ProductPageList | null>(null)
  const getContent = useContent().getContent

  function hydrateStore() {
    getContent<ProductVariants>({
      contentTypeCriterion: ["product"],
      parentLocationIdCriterion: [parseInt(PRODUCTS_LOCATION_ID)],
    }).then(result => {
      productVariantsRaw.value = result
    })

    getMarketingData().then(result => {
      marketingData.value = result
    })

    getProductData().then(data => {
      eCommerce.value = data
    })
  }

  /* Create product variants object and set active licence */
  watchEffect(async () => {
    if (productVariantsRaw.value) {
      productsMap.value = getGroupedProductVariants(
        productVariantsRaw.value,
        eCommerce.value,
        marketingData.value,
      )
    }

    if (productsMap.value && product.value) {
      const activeProduct = productsMap.value[product.value]
      if (variant.value && activeProduct) {
        const activeVariant = activeProduct.variants.find(
          v => v.variantKey === variant.value,
        )
        if (activeVariant) {
          licence.setActiveLicence(activeVariant?.ean)
        }
      }
    }

    if (eCommerce.value.length > 0) {
      for (const productKey in productsMap.value) {
        const product = productsMap.value[productKey]
        for (const variant of product.variants) {
          const matchingEcommerce = eCommerce.value.find(
            ecommerceProduct => ecommerceProduct.ean === variant.ean,
          )
          if (matchingEcommerce) {
            variant.eCommerce = matchingEcommerce
          }
        }
      }
    }

    for (const productKey in productsMap.value) {
      const product = productsMap.value[productKey]
      product.variants.sort((a, b) =>
        a.shortVariantLabel.localeCompare(b.shortVariantLabel),
      )
    }
  })

  /* Set active product and active variant */
  watchEffect(() => {
    activeProduct.value = productsList.value.find(
      ({ productKey }) => productKey === product.value,
    )
    localStorage.setItem(
      "lastActiveProduct",
      activeProduct.value?.productKey ?? "exphil",
    )
    if (
      activeProduct.value &&
      activeProduct.value.variants.length === 1
    ) {
      activeVariant.value = activeProduct.value.variants[0]
    } else {
      activeVariant.value = activeProduct.value?.variants.find(
        v => v.variantKey === variant.value,
      )
    }
  })

  /* Create a implicit object in list format for easier use */
  watchEffect(() => {
    productsList.value = []
    for (const productKey in productsMap.value) {
      const p = productsMap.value[productKey]
      const test = { ...p, productKey }
      productsList.value?.push(test)
    }
  })

  /* Create a implicit flat array with product variants  */
  watchEffect(() => {
    for (const productKey in productsMap.value) {
      const p = productsMap.value[productKey]
      for (const variant of p.variants) {
        if (
          !productVariants.value.some(
            v => v.variantKey === variant.variantKey,
          )
        ) {
          productVariants.value.push(variant)
        }
      }
    }
  })

  /* Add a flag to the products of which the user has a licence for */
  watchEffect(() => {
    for (const productKey in productsMap.value) {
      const product = productsMap.value[productKey]
      for (const variant of product.variants) {
        if (licence.hasLicenceByEan(variant.ean ?? "")) {
          variant.userHasLicence = true
          variant.licence = licence.validLicenceData?.find(
            licence => licence.ean === variant.ean,
          )
        } else {
          variant.userHasLicence = false
        }
      }
    }
  })

  return {
    /** State */
    productVariants,
    product,
    variant,
    activeVariant,
    activeProduct,
    productsList,
    eCommerce,
    hydrateStore,
    /** Computed */
    groupedProductVariants: productsMap,
    marketingData,
    productVariantsRaw,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useProductVariantsStore, import.meta.hot),
  )
