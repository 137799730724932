import { acceptHMRUpdate, defineStore } from "pinia"
import type { PlayListSubject, Track } from "~/types/audio"

export const useAudioStore = defineStore("audio", () => {
  /** State */
  const activePlayer = ref(false)
  const playListSubject = ref<PlayListSubject | undefined>()
  const playList = ref<Track[]>([])

  /** Actions */
  const togglePlayer = () => {
    activePlayer.value = !activePlayer.value
  }

  const setPlayListSubject = (
    title: string,
    priority: number,
    playTime: number,
  ) => {
    playListSubject.value = { title, priority, playTime }
  }

  const setPlayList = (tracks: Track[]) => {
    playList.value = tracks
  }

  const setCurrentTrack = (id: number) => {
    playList.value.forEach(track => (track.active = track.id === id))
  }

  return {
    /** State */
    activePlayer,
    playListSubject,
    playList,

    /** Actions */
    togglePlayer,
    setPlayListSubject,
    setPlayList,
    setCurrentTrack,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useAudioStore, import.meta.hot),
  )
