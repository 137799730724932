<template>
  <ul id="skeleton-card-list" class="skeleton-card-list pt-10">
    <transition-group name="list">
      <li
        v-for="(_, key) in Array.from({ length: 4 })"
        id="skeleton-card-skeleton-list-item"
        :key="key"
      >
        <div class="relative">
          <u-skeleton
            id="skeleton-card-skeleton-button"
            class="skeleton-card-button skeleton-content"
          />
        </div>
      </li>
    </transition-group>
  </ul>
</template>

<style scoped lang="postcss">
.skeleton-content {
  @apply mb-[48px] h-[537px] w-full overflow-hidden rounded-[20px] bg-[--theme-darkest];
}
</style>
