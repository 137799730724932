<script setup lang="ts">
import UButtonNew from "../Interactions/UButtonNew.vue"

defineProps({
  canGoBack: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  goBack: {
    type: Function as PropType<() => void>,
    required: true,
  },
})

const route = useRoute<"subject">()
</script>

<template>
  <div class="flex justify-center w-full gap-4">
    <UArticleHeaderWrapper
      class="pointer-events-none z-20 flex h-fit max-w-[90em] items-center justify-between gap-4 py-4 md:justify-start"
    >
      <UButtonNew
        class="pointer-events-auto"
        circle
        aria-label="Tilbake til kapittelet"
        theme="white"
        variant="filled"
        :to="`/produkter/${route.params.product}/${route.params.variant}/${route.params.subject}`"
      >
        <template #prepend>
          <u-icon name="minimize" />
        </template>
      </UButtonNew>
      <UButtonNew
        v-if="canGoBack"
        class="pointer-events-auto"
        pill
        no-padding-left-and-right
        theme="white"
        variant="filled"
        @click="goBack"
      >
        <template #prepend>
          <u-icon name="arrow-up" size="sm" />
        </template>
        Forrige
      </UButtonNew>
    </UArticleHeaderWrapper>
  </div>
</template>
