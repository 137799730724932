<script setup lang="ts">
import type { NavProps } from "~/types/nav.d"
import type { Folder } from "~/types/folder"
import { formatForURL } from "~/utilities/composables"
import { useMediaCounts } from "~/composables/useMediaCounts"

const props = defineProps({
  folder: {
    type: Object as PropType<Folder>,
    default: () => {},
  },
  data: {
    type: Array as PropType<NavProps["data"]>,
    required: true,
    default: () => [],
  },
  pageType: {
    type: String as PropType<string>,
    default: "subject",
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const getSubjectsInFolder = (id: number) => {
  return props.data.filter(i => i.parentLocationId === id)
}

const route = useRoute()
const router = useRouter()
const hash = computed(() => formatForURL(props.folder.title))

const isOpen = computed(() => {
  return route.hash.slice(1) === hash.value
})

function closeIfOpen(event: Event) {
  if (isOpen.value) {
    router.replace({ hash: "" })
    event.preventDefault()
  }
}

const folderContent = computed(() =>
  getSubjectsInFolder(props.folder.id),
)

const mediaCounts = useMediaCounts(props.folder)

const isFolderEmpty = computed(() => {
  const counts = mediaCounts.data.value
  return (
    !!counts &&
    counts.articles === 0 &&
    counts.videos === 0 &&
    counts.quizes === 0
  )
})
</script>

<template>
  <a
    class="flex items-center justify-between w-full pl-3 folder-btn hover:text-u-contrast"
    text-class="!whitespace-normal text-left"
    left-align
    data-pendo="folder-list-item"
    :class="[
      {
        opened: isOpen,
        'pointer-events-none opacity-60':
          isFolderEmpty && folderContent,
      },
    ]"
    :href="`#${hash}`"
    @click="closeIfOpen($event)"
  >
    <div
      class="flex max-w-[160px] gap-3 text-xl font-extrabold leading-6 md:max-w-[180px] lg:max-w-[250px] xl:max-w-[300px]"
    >
      <span> {{ folder.priority }}</span>
      <h3>
        {{ folder.title }}
      </h3>
    </div>

    <UButtonNew
      v-if="!isFolderEmpty"
      tabindex="-1"
      aria-disabled="true"
      theme="white"
      size="sm"
      variant="quite"
      :aria-expanded="isOpen"
      aria-controls="folderContentList"
      ><UIcon
        :name="isOpen ? 'chevron-up' : 'chevron-down'"
        size="sm"
      ></UIcon
    ></UButtonNew>
  </a>
  <ul v-if="isOpen" id="folderContentList">
    <li
      v-for="(i, key) in folderContent"
      :key="key"
      class="folder-subject"
    >
      <u-nav-list-item
        :item="i"
        :page-type="pageType"
        :licence="licence"
      />
    </li>
  </ul>
</template>

<style scoped lang="postcss">
.folder-btn {
  @apply flex w-full gap-3 py-3 font-national2 text-[22px] font-bold;
  @apply flex border-b-2 border-white border-opacity-20;

  &.opened {
    @apply border-b-2 border-opacity-100;
  }
}
.folder-subject:last-child {
  @apply pb-6;
}
</style>
