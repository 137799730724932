export function listenForServiceWorkerMessages() {
  // Listen for messages from the service worker
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("message", event => {
      if (event.data.cache.type === "UPDATED") {
        console.info(
          "The following cache entry has been updated",
          event.data.cache,
        )
        console.info(
          "Reloading the page to get the latest version of the cache",
        )
        //location.reload()
      }
    })
  }
}

export function sendMessageToSW(message: PWA.MessageToSW): void {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.active?.postMessage(message)
    })
  } else {
    console.info("Service Worker is not supported in this browser.")
  }
}
