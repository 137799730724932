<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components"

const props = defineProps({
  modelValue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  alignY: {
    type: String as PropType<string>,
    default: "center",
  },
  alignX: {
    type: String as PropType<string>,
    default: "center",
  },
  customClass: {
    type: String as PropType<string>,
    default: "center",
  },
  customInnerClass: {
    type: String as PropType<string>,
    default: "",
  },
  customTopClass: {
    type: String as PropType<string>,
    default: "",
  },
  title: {
    type: String as PropType<string>,
    default: "",
  },
  description: {
    type: String as PropType<string>,
    default: "",
  },
  body: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  headerClass: {
    type: String as PropType<string>,
    default: "",
  },
  header: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  closeOnClickOutside: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
})

const emit = defineEmits(["update:modelValue", "close"])

const show = computed({
  get: () => props.modelValue,
  set: val => emit("update:modelValue", val),
})
</script>

<template>
  <teleport to="#theme">
    <transition name="fade" mode="out-in">
      <div
        v-if="show"
        id="defaultModal"
        tabindex="-1"
        :class="
          customTopClass || {
            'justify-start': alignX === 'start',
            'justify-end': alignX === 'end',
            'justify-center': alignX === 'center',
            'items-start': alignY === 'start',
            'items-bottom': alignY === 'bottom',
            'items-center': alignY === 'center',
          }
        "
        class="fixed top-0 left-0 right-0 z-30 flex m-auto mx-auto overflow-x-hidden overflow-y-auto h-dvh bg-portal-indigo-900 bg-opacity-90 backdrop-blur backdrop-saturate-100 md:inset-0"
        aria-modal="true"
        role="dialog"
      >
        <transition name="fade" mode="out-in">
          <div
            v-if="body"
            class="relative w-full max-w-xl max-h-lg sm:p-4"
            :class="customClass"
          >
            <div
              v-on-click-outside="
                closeOnClickOutside
                  ? () => {
                      show = false
                      emit('close')
                    }
                  : () => {}
              "
              class="relative overflow-hidden bg-white rounded-lg h-dvh drop-shadow-xl sm:h-auto"
              :class="customInnerClass"
            >
              <div
                v-if="header"
                class="flex justify-between p-5"
                :class="headerClass"
              >
                <div>
                  <slot name="header" />
                  <h1 v-if="title" class="text-2xl font-semibol">
                    {{ title }}
                  </h1>
                  <div v-if="description" class="text-gray-600">
                    {{ description }}
                  </div>
                </div>

                <UButtonNew
                  size="sm"
                  circle
                  variant="quite"
                  class="hover:text-[--theme-medium]"
                  @click="
                    () => {
                      show = false
                      $emit('update:modelValue')
                      $emit('close')
                    }
                  "
                >
                  <template #prepend><UIcon name="close" /></template
                ></UButtonNew>
              </div>
              <div class="p-5">
                <slot />
              </div>
            </div>
          </div>
          <div
            v-else
            v-on-click-outside="
              closeOnClickOutside
                ? () => {
                    show = false
                    emit('close')
                  }
                : () => {}
            "
          >
            <slot />
          </div>
        </transition>
      </div>
    </transition>
  </teleport>
</template>
