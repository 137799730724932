<script setup>
const personalization = usePersonalizationStore()

function setFeatureFlagToDisabled() {
  isFeatureEnabled.value = false
}
</script>

<template>
  <div
    v-if="personalization?.settings"
    class="h-fit w-full overflow-hidden rounded-lg bg-white bg-opacity-10 p-6"
  >
    <div class="mb-4 flex items-center justify-start gap-3">
      <p class="text-xl lg:text-2xl">Innstillinger</p>
    </div>

    <u-checkbox
      v-model="personalization.settings.autoShowProgress"
      label="Vis progresjon automatisk"
      size="sm"
      dark
    />
    <div class="mt-5 flex w-fit flex-col gap-3 xl:flex-row xl:gap-4">
      <UButtonNew
        id="fjern-progresjon"
        theme="light"
        class="w-fit"
        size="sm"
        @click="personalization.clearReadHistory"
      >
        Fjern progresjon
      </UButtonNew>
      <UButtonNew
        id="fjern-progresjon"
        theme="light"
        class="w-fit"
        size="sm"
        @click="setFeatureFlagToDisabled"
      >
        Deaktivere 'Feature Flags' midlertidig
      </UButtonNew>
    </div>
  </div>
</template>
