<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components"
import type { Article } from "~/types/article"

defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const emit = defineEmits(["click"])
const showCaption = ref(false) as Ref<boolean>
</script>

<template>
  <div v-if="data?.imageCaption?.html" class="image-caption">
    <transition name="fade" mode="out-in">
      <div
        v-if="showCaption"
        id="image-caption"
        v-on-click-outside.bubble="() => (showCaption = false)"
        class="absolute bottom-0 right-0 z-10 w-full bg-black bg-opacity-50 p-10 py-5 !font-national2 text-[--theme-lightest] backdrop-blur-[4px]"
      >
        <div class="mb-20">
          <div class="flex w-full justify-end">
            <UButtonNew
              variant="quite"
              theme="white"
              @click="showCaption = false"
            >
              <template #prepend>
                <UIcon name="close" />
              </template>
            </UButtonNew>
          </div>
          <component
            :is="{
              name: 'richtext',
              template: data?.imageCaption?.html,
            }"
          />
        </div>
      </div>
    </transition>

    <div
      v-if="!showCaption"
      class="absolute bottom-2 right-2 md:bottom-0 md:right-0"
    >
      <UButtonNew
        v-model:active="showCaption"
        :aria-expanded="showCaption"
        aria-controls="image-caption"
        class="m-3"
        pill
        size="sm"
        variant="filled"
        @click.stop
      >
        <template #prepend> <u-icon name="camera" /> </template>
        Bildetekst
      </UButtonNew>
    </div>
  </div>
</template>
