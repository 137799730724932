import type { DocumentNode } from "graphql"
import * as Sentry from "@sentry/vue"
import { getTokenData } from "~/utilities/auth"

interface Query {
  query: DocumentNode
  variables?: Record<string, unknown>
}

/**
 *
 * @param doc
 * @returns the original string query
 */
function stringify(doc: DocumentNode) {
  return doc.loc && doc.loc.source.body
}

/**
 * GraphQL client
 * @param doc
 */
export async function graphqlClient(doc: Query): Promise<any> {
  const products = useProductVariantsStore()
  const EAN = products.activeVariant?.ean
  const tokenData = getTokenData()
  const licence = useLicenceStore()
  const licenceData = computed(() => licence.licenceData)

  const getEan = async () => {
    if (
      !Array.isArray(licenceData.value) &&
      tokenData?.access_token
    ) {
      await licence.setLicenceData()
    }
    return licenceData.value?.find(item => item.ean === EAN)?.ean
  }

  const ean = (await getEan()) || ""

  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  const authHeaders = {
    Ean: ean,
    Authorization: `Bearer ${tokenData?.access_token}`,
  }

  const headers = tokenData?.access_token
    ? { ...defaultHeaders, ...authHeaders }
    : defaultHeaders

  const config = {
    method: "POST",
    headers: headers,
    data: {
      query: stringify(doc.query),
      variables: doc.variables,
    },
  }

  try {
    const { data } = await api.ibexa(config)
    return data
  } catch (errors) {
    Sentry.captureException(errors)
    return { errors }
  }
}
