<script setup lang="ts">
const props = defineProps({
  totalArticlesDone: {
    type: Number,
    default: 0,
  },
  totalChildren: {
    type: Number,
    default: 0,
  },
  chapterStatus: {
    type: String,
    default: "",
  },
})

const totalArticlesDoneSetMaxLimit = ref(props.totalArticlesDone)
watch(
  () => props.totalArticlesDone,
  newValue => {
    if (props.totalArticlesDone > props.totalChildren) {
      totalArticlesDoneSetMaxLimit.value = props.totalChildren
    } else {
      totalArticlesDoneSetMaxLimit.value = newValue
    }
  },
  { immediate: true },
)

type ChapterStatus = "not-started" | "in-progress" | "done"
type ChapterStatusIcon =
  | "circle-hollow"
  | "circle-filled-half"
  | "circle-filled"

const mapIcons = (status: ChapterStatus): ChapterStatusIcon => {
  switch (status) {
    case "not-started":
      return "circle-hollow"
    case "in-progress":
      return "circle-filled-half"
    case "done":
      return "circle-filled"
  }
}
</script>

<template>
  <div
    class="px-2 text-base text-white rounded-full opacity-75 group-hover:opacity-100"
  >
    <div class="flex items-center gap-2">
      <div>
        {{ totalArticlesDoneSetMaxLimit }}/{{ props.totalChildren }}
      </div>
      <u-icon
        :name="mapIcons(chapterStatus)"
        size="xs"
        :aria-label="`Artikler ${props.chapterStatus}`"
      />
    </div>
  </div>
</template>
