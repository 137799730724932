const baseUrl = `${
  import.meta.env.VITE_AM_CORE
}/uf-auth-cookie-cleaner/delete-cookies`

const cookieDomain =
  import.meta.env.VITE_AM_CORE ===
  "https://am.core.prod.aschehoug.cloud"
    ? ".prod.aschehoug.cloud"
    : ".test.aschehoug.cloud"
const cookiesToDelete = {
  "am-frontend-sso": cookieDomain,
  "am-sticky": cookieDomain,
  amlbcookie: cookieDomain,
}
const returnUrl = `https://${import.meta.env.VITE_HOST}`

export default function createStandardCookieDeleteUrl() {
  return createCookieDeletionUrl(baseUrl, cookiesToDelete, returnUrl)
}

export function createCookieDeletionUrl(
  baseUrl: string,
  cookies: typeof cookiesToDelete,
  returnUrl: string,
) {
  let queryString = Object.entries(cookies)
    .map(([cookieName, domain]) => {
      return `cookies[${encodeURIComponent(
        cookieName,
      )}]=${encodeURIComponent(domain)}`
    })
    .join("&")
  queryString += `&returnUrl=${encodeURIComponent(returnUrl)}`
  return `${baseUrl}?${queryString}`
}
