import type {
  ImageField,
  ImageRelationField,
} from "~/models/Content/BaseField"
import type {
  ImageAsset,
  RelationList,
  Tag,
  User,
} from "~/models/Content/SearchResult"
import { selectionTypes } from "./selection-types"

export const formatStringField = (value: unknown): string => {
  return String(value ?? "")
}

export const formatTextField = (value: unknown): string => {
  return String(value ?? "")
}

export const formatRichTextField = (value: unknown): string => {
  if (!value || typeof value !== "object") return ""

  const html5Content = (value as { html5?: string })?.html5
  if (!html5Content) return ""

  // Remove XML declaration and clean up the HTML
  return html5Content.replace(/^<\?xml[^>]*>\n/, "").trim()
}

export const formatImageAssetField = (
  value: unknown,
): ImageAsset | null => {
  if (!value) {
    return null
  }

  const imageAsset = value as ImageRelationField
  if (!imageAsset.destinationContentId) {
    return null
  }

  return {
    id: parseInt(imageAsset.destinationContentId),
    src: `${import.meta.env.VITE_IBEXA_BASEURL}/image/${imageAsset.destinationContentId}/fullwidth`,
    alt: imageAsset.alternativeText ?? "",
    width: imageAsset.width ? parseInt(imageAsset.width) : undefined,
    height: imageAsset.height
      ? parseInt(imageAsset.height)
      : undefined,
    focalPointX: imageAsset.additionalData?.focalPointX
      ? parseInt(imageAsset.additionalData.focalPointX)
      : undefined,
    focalPointY: imageAsset.additionalData?.focalPointY
      ? parseInt(imageAsset.additionalData.focalPointY)
      : undefined,
  }
}

export const formatImageField = (
  value: unknown,
): ImageAsset | null => {
  const imageAsset = value as ImageField
  if (!imageAsset) return null

  return {
    id: null,
    src: imageAsset.uri,
    alt: imageAsset.alternativeText,
    width: imageAsset.width ? parseInt(imageAsset.width) : undefined,
    height: imageAsset.height
      ? parseInt(imageAsset.height)
      : undefined,
  }
}

export const formatObjectRelationField = (
  value: unknown,
): RelationList => {
  if (!value || typeof value !== "object") {
    return { contendIds: [] }
  }

  const fieldValue = value as object
  let contentIds: number[] = []

  if ("destinationContentIds" in fieldValue) {
    contentIds = (fieldValue as { destinationContentIds: number[] })
      .destinationContentIds
  } else if ("destinationContentId" in fieldValue) {
    const id = (fieldValue as { destinationContentId: number })
      .destinationContentId
    contentIds = [id]
  }

  return {
    contendIds: contentIds.map(id => Number(id)),
  }
}

export const formatTagsField = (value: unknown): Tag[] => {
  if (!Array.isArray(value)) return []

  return value.map(tag => ({
    key: String(tag.remote_id ?? ""),
    value: tag.keywords?.["eng-GB"] ?? "",
  }))
}

export const formatBooleanField = (value: unknown): boolean => {
  if (typeof value === "string") {
    return value.toLowerCase() !== "false"
  }
  return Boolean(value)
}

export const formatAuthorField = (value: unknown): User | null => {
  if (!value || !Array.isArray(value) || !value[0]) {
    return null
  }

  const author = value[0] as User

  return {
    id: Number(author.id),
    name: author.name ?? "",
    email: author.email ?? "",
  }
}

export const formatSelectionField = (
  value: unknown,
  contentType: string,
  fieldKey: string,
): string => {
  if (!Array.isArray(value) || value.length === 0) {
    return ""
  }

  const selectionValue = String(value[0])
  const mappings = selectionTypes[contentType]?.[fieldKey]

  if (!mappings) {
    console.error(
      `Missing selection type mapping for contentType: "${contentType}", fieldKey: "${fieldKey}", value: "${selectionValue}"`,
    )
    return selectionValue
  }

  return mappings[selectionValue] || selectionValue
}
