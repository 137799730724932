import type { IbexaQuizContentType } from "~/types/ibexa"

export default (articles: IbexaQuizContentType[]): any => {
  if (!articles?.length) return []

  return articles
    .map((i: IbexaQuizContentType) => {
      if (i._type.identifier === "quiz") {
        return {
          id: i.quizId,
          title: i.title,
        }
      }
    })
    .filter(Boolean)
}
