<script setup>
const products = useProductVariantsStore()
</script>

<template>
  <div class="layout">
    <ULogo :product="products.activeProduct" />
    <div class="layout__view">
      <router-view />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.layout {
  @apply min-h-screen max-w-full bg-platform-paper px-5 text-black md:py-20;
  display: grid;
  justify-items: center;
  grid-auto-rows: max-content;
}

.layout__title {
  @apply !text-black;
  width: 100%;
  max-width: 90rem;
}

.layout__view {
  width: 100%;
  max-width: 70rem;
}
</style>
