import { warn } from "vue"
import { SkeletorSymbol, DEFAULT_OPTIONS } from "./constants"
import type { SkeletorOptions } from "./types"

export default function useSkeletor(): unknown {
  const skeletor = inject<SkeletorOptions>(
    SkeletorSymbol,
    DEFAULT_OPTIONS,
  )

  if (!skeletor) {
    warn("Skeletor is not installed on this Vue application.")
  }

  return skeletor
}
