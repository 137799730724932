<script setup lang="ts">
import { createVippsLoginUrl, loginVipps } from "~/utilities/auth"

const route = useRoute<"subject">()
const fromPath = route.query.frompath
const path = (
  Array.isArray(fromPath) ? fromPath[0] : fromPath
) as string
const vippsUrl = createVippsLoginUrl()
const productRoute = useRoute<"product">()
</script>

<template>
  <div
    class="md:px-18 flex w-full flex-col items-center justify-center gap-6 py-16"
  >
    <div class="flex flex-col gap-8">
      <div class="richtext text-4xl">
        <p class="max-w-[20em] text-[--theme-darkest]">
          Du må logge inn for å få tilgang til alt innhold i Kunne
          <span>{{
            `${productRoute.params.product} ${productRoute.params.variant}`
          }}</span>
        </p>
      </div>

      <div
        class="flex items-center justify-start lg:gap-2 <lg:flex-col <lg:items-start <lg:gap-6"
      >
        <a
          v-wave
          class="block rounded-full"
          :href="vippsUrl"
          @click.prevent="
            loginVipps(
              `/produkter/${
                productRoute.params.product +
                '/' +
                productRoute.params.variant
              }`,
            )
          "
        >
          <img
            src="/images/vipps-logginn.svg"
            alt="Logg inn med Vipps"
            class="h-18"
          />
        </a>
        <div class="ml-2 font-national2 text-lg">
          Har du ikke vipps?
          <a
            href="https://vipps.no/hjelp/vipps/kom-i-gang-med-vipps/hvordan-skaffer-jeg-meg-vipps/"
            target="_blank"
            style="font: inherit; text-decoration: underline"
            >Slik går du frem.</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<route lang="yaml">
name: login
meta:
  title: Logg inn
  layout: basic
  loggedInRedirect: true
</route>
