<script setup lang="ts">
defineProps({
  usp: {
    type: String as PropType<string>,
    default: "",
  },
})

const isDesktop = useMediaQuery("(min-width: 1024px)")
</script>

<template>
  <li v-if="usp.length > 0" class="flex w-fit items-center gap-4">
    <UIcon
      name="check-filled"
      :size="isDesktop ? 'auto' : 'lg'"
      aria-hidden="true"
    />
    <span
      class="text-marketing-mobile-p1-p1-regular-m lg:text-marketing-dekstop-p1-p1-regular-m font-national2condensed text-[--theme-dark]"
      >{{ usp }}</span
    >
  </li>
</template>
