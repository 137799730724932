import type { DocumentNode } from "graphql"

interface Query {
  query: DocumentNode
  variables?: Record<string, unknown>
}

interface GraphQLResponse<T> {
  data?: T
  errors?: unknown
}

function stringify(doc: DocumentNode) {
  return doc.loc && doc.loc.source.body
}

export async function graphqlClient<T>(
  doc: Query,
  endpoint: string = "",
): Promise<{ data: T } | { errors: unknown }> {
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  const config = {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify({
      query: stringify(doc.query),
      variables: doc.variables,
    }),
  }

  const baseUrl = "https://api.crystallize.com"
  const fullUrl = `${baseUrl}/${import.meta.env.VITE_CRYSTALLIZE_TENANT_NAME}${endpoint}`

  try {
    const response = await fetch(fullUrl, config)

    if (!response.ok) {
      return { errors: `HTTP error - status: ${response.status}` }
    }

    const rawText = await response.text()

    let jsonResponse: GraphQLResponse<T>
    try {
      jsonResponse = JSON.parse(rawText)
    } catch (parseError) {
      return { errors: "Invalid JSON in response" }
    }

    if (jsonResponse.errors) {
      return { errors: jsonResponse.errors }
    }

    if (!jsonResponse.data) {
      return { errors: "No data in response" }
    }

    return { data: jsonResponse.data as T }
  } catch (errors) {
    return { errors }
  }
}
