import { Folder } from "~/types/folder"
import { Subject } from "~/types/subject"
import { useQuery } from "@tanstack/vue-query"

interface MediaCounts {
  articles: number
  videos: number
  podcasts: number
  quizes: number
}

export function useMediaCounts(
  t: MaybeRef<Folder | Subject | undefined>,
) {
  return useQuery({
    queryKey: ["media-count", computed(() => unref(t)?.id)],
    enabled: computed(() => !!unref(t)),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    async queryFn() {
      const { data } = await api.ibexaApi.get(
        `/location/${unref(t)?.id}/count`,
      )

      return data as MediaCounts
    },
  })
}
