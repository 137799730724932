import type { IbexaAudioContentType } from "~/types/ibexa"

export default (articles: IbexaAudioContentType[]): any => {
  if (!articles?.length) return []

  return articles
    .map((i: IbexaAudioContentType) => {
      if (i._type.identifier === "audio") {
        return {
          caption: i.caption,
          id: i.id,
          metadata: JSON.parse(
            i.metadata.replaceAll(
              "media.lokus.no",
              "media.aschehoug.cloud",
            ),
          ),
          shortTitle: i.shortTitle,
          timestamps: i.timestamps.map((stamp, key) =>
            Object.assign(stamp, {
              id: i.id + "-audio-timestamp-" + key,
            }),
          ),
          title: i.title,
          _type: i._type,
        }
      }
    })
    .filter(Boolean)
}
