import type { Criterion } from "~/models/Search"
import { ContentType } from "~/models/Content/ContentType"

export default () => {
  const searchPath = "/search"
  const emptyQuery = { text: "*" }

  const getLocationIdCriterion = (
    locationIds: number[],
  ): Partial<Criterion> => ({ locationIdCriterion: locationIds })
  const getContentIdCriterion = (
    contentIds: number[],
  ): Partial<Criterion> => ({ contentIdCriterion: contentIds })
  const getSubtreeCriterion = (
    subtrees: string[],
  ): Partial<Criterion> => ({ subtreeCriterion: subtrees })
  const getContentTypeCriterion = (
    contentTypes: ContentType[],
  ): Partial<Criterion> => ({ contentTypeCriterion: contentTypes })

  // Fields necessary to build a card
  const cardFields = [
    "title",
    "short_title",
    "level_tag",
    "grades",
    "levels",
    "subjects",
    "label",
    "file",
    "metadata",
    "thumbnail",
    "image",
    "view_mode",
    "video",
    "link_external",
    "activity_tag",
  ]

  return {
    searchPath,
    emptyQuery,
    getLocationIdCriterion,
    getContentIdCriterion,
    getSubtreeCriterion,
    getContentTypeCriterion,
    cardFields,
  }
}
