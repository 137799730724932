import { useQuery } from "@tanstack/vue-query"
import { graphqlClient } from "~/graphql/client/ecommerceClient"
import { GetEcommerceCatalogueDocument } from "~/graphql/documents/ecommerce/catalogue"
import { EcommerceProduct } from "~/types/ecommerce/product"

interface CatalogueResponse {
  catalogue: {
    children: EcommerceProduct[]
  }
}

const fetchEcommerceCatalogue = async (): Promise<
  EcommerceProduct[]
> => {
  const response = await graphqlClient<CatalogueResponse>(
    {
      query: GetEcommerceCatalogueDocument,
    },
    "/catalogue",
  )

  if ("errors" in response) {
    throw new Error("API error: " + JSON.stringify(response.errors))
  }

  if (!response.data) {
    throw new Error("No data in response")
  }

  if (!response.data.catalogue) {
    throw new Error("No catalogue in response data")
  }

  if (!Array.isArray(response.data.catalogue.children)) {
    throw new Error("Catalogue children is not an array")
  }

  return response.data.catalogue.children
}

export function useEcommerceCatalogue() {
  return useQuery({
    queryKey: ["ecommerceCatalogue"],
    queryFn: fetchEcommerceCatalogue,
  })
}
