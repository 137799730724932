<script setup lang="ts">
const props = defineProps({
  done: Boolean,
})
</script>

<template>
  <u-icon
    v-if="done"
    name="check"
    size="xxs"
    class="scale-[0.8] rounded-full bg-white p-[2.75px] text-[--theme-darkest]"
    :aria-label="props.done ? 'Artikkel lest' : 'Artikkel ikke lest'"
  />
  <u-icon
    v-else
    name="circle-hollow"
    size="xs"
    aria-label="Artikkel ikke lest"
  />
</template>
