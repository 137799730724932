import gql from "graphql-tag"

export default gql`
  query ($id: Int!) {
    _repository {
      location(locationId: $id) {
        id
        priority
        hidden
        parentLocationId
        parentLocation {
          content {
            _name
          }
          priority
        }
        content {
          ... on ArticleContent {
            title
            shortTitle
            _url
            _type {
              identifier
            }
            template
            bg
            color
            intro {
              plaintext
              html5
            }
            body {
              plaintext
              html5
            }
            image {
              id
              height
              width
              variation(identifier: fullwidth) {
                uri
              }
              alternativeText
              text
              additionalData {
                focalPointX
                focalPointY
              }
            }
            imageCaption {
              html5
            }
            imageSize
            relatedArticles {
              ... on VideoContent {
                id
                title
                image {
                  alternativeText
                  variation(identifier: fullwidth) {
                    uri
                  }
                }
                previewTitle
                publishedBy
                published
                timestamps {
                  description
                  time
                }
                metadata
                embedCode
                transcription {
                  plaintext
                  html5
                }
                captions {
                  captionFile {
                    uri
                    fileName
                  }
                  language
                }
              }
              ... on QuizContent {
                id
                title
                quizId
              }
              ... on AudioContent {
                id
                title
                shortTitle
                caption
                timestamps {
                  description
                  time
                }
                metadata
              }
              _type {
                identifier
              }
            }
            _type {
              identifier
            }
            image {
              alternativeText
              variation(identifier: fullwidth) {
                uri
              }
            }
            hasPictureFrame
            pictureColor
            pictureText {
              html5
            }
            _info {
              currentVersion {
                versionNumber
              }
              locations {
                children {
                  totalCount
                }
              }
              currentVersion {
                versionNumber
              }
              relations {
                destinationContent {
                  _url
                  ... on SidenoteContent {
                    id
                    title
                    type
                    image {
                      uri
                      height
                      width
                      alternativeText
                    }
                    video {
                      id
                      title
                      image {
                        alternativeText
                        variation(identifier: fullwidth) {
                          uri
                        }
                      }
                      previewTitle
                      publishedBy
                      published
                      timestamps {
                        description
                        time
                      }
                      metadata
                      embedCode
                      transcription {
                        plaintext
                        html5
                      }
                      captions {
                        captionFile {
                          uri
                          fileName
                        }
                        language
                      }
                    }
                    richtext {
                      html5
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
