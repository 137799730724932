import type { CheckoutRes } from "~/types/checkout.d"

export async function postCheckout(
  productRoute: string,
  EAN: string,
): Promise<CheckoutRes | null> {
  const body = {
    productId: EAN,
    paymentSource: "VIPPS",
  }

  const redirectUrl = `${location.origin}/${productRoute}?pay-redirect=true`

  try {
    const res = await api.ecommerce.post(
      `/rest/checkout?productSource=CRYSTALLIZE_KUNNE&redirectUrl=${encodeURIComponent(
        redirectUrl,
      )}`,
      body,
    )
    localStorage.setItem("session_id", res.data.sessionId)
    return res.data
  } catch (error) {
    return null
  }
}
