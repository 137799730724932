import * as Sentry from "@sentry/vue"
import { loginPageUrl } from "~/utilities/urls"

export const useLoginPageUrl = () => {
  const productVariantStore = useProductVariantsStore()

  const loginUrl = computed(() => {
    if (productVariantStore.product) {
      return loginPageUrl(productVariantStore.product)
    } else {
      Sentry.captureException(
        new Error(
          "Product or variant not found to create loginPageUrl in subject",
        ),
      )
    }
  })

  return loginUrl.value
}
