import getMathTaskSolutionFormat, {
  AssignmentSolutions,
  FormattedMathTaskSolutions,
} from "~/api/getMathTasks"
import * as Sentry from "@sentry/vue"
import { FasitSidenote } from "~/models/FasitSidenote"
import { Relation } from "~/types/article"

export async function getMathTaskSolutions(articleId: number) {
  let data: AssignmentSolutions | undefined

  try {
    data = await getMathTaskSolutionFormat(articleId)
  } catch (e) {
    Sentry.captureException(
      `Missing suggested solutions for math tasks in article [id=${articleId}]`,
    )
  }

  if (!data) return undefined

  return data
}

type MathTaskSidenotes = {
  solutionSidenote: FasitSidenote | undefined
  suggestedSolutionSidenotes: Relation[]
}

export function formatMathSidenotes(
  assignments:
    | Map<number, FormattedMathTaskSolutions>
    | undefined = new Map<number, FormattedMathTaskSolutions>(),
): MathTaskSidenotes {
  const solutions: string[] = []
  const suggestedSolutionSidenotes: Relation[] = []

  for (const [, assignment] of assignments) {
    if (assignment.solution) {
      solutions.push(assignment.solution.solutionText.html5)
    }

    if (assignment.suggestedSolution) {
      assignment.suggestedSolution.sections?.map(s => {
        const relations = s.relations
        suggestedSolutionSidenotes.push(...relations)
      })
    }
  }

  let solutionSidenote: FasitSidenote | undefined

  if (solutions.length !== 0) {
    solutionSidenote = {
      identifier: "solution",
      title: "Fasit",
      resourceUrl: "fasit",
      solutionText: solutions.join("<br>"),
    }
  } else {
    solutionSidenote = undefined
  }

  return {
    solutionSidenote,
    suggestedSolutionSidenotes,
  }
}

type MathTemplates = "UMathTaskWithImageArticle" | "UMathTaskArticle"

export function isMathTemplate(
  value: string,
): value is MathTemplates {
  return (
    value === "UMathTaskWithImageArticle" ||
    value === "UMathTaskArticle"
  )
}
