export const isOnline = ref(navigator.onLine)

const updateOnlineStatus = () => {
  isOnline.value = navigator.onLine
  console.info(
    `Online status changed: ${isOnline.value ? "Online" : "Offline"}`,
  )
}

window.addEventListener("online", updateOnlineStatus)
window.addEventListener("offline", updateOnlineStatus)
