<script setup lang="ts">
import { NavListChapter } from "~/types/nav"
import { Subject as Chapter } from "~/types/subject"

const licenceStore = useLicenceStore()
const price = useProductPrice()

const props = defineProps({
  chapter: {
    type: Object as PropType<Chapter | NavListChapter>,
    required: true,
  },
  small: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const isProductFree = computed(() => {
  if (typeof price?.value === "number") {
    return price?.value === 0
  }
  return false
})

const isSubjectFree = computed(() => {
  return props.chapter.totalChildren !== 0
})

const hasValidLicence = computed(() => {
  return licenceStore.hasValidLicence
})
</script>

<template>
  <UBadge
    v-if="
      chapter && isSubjectFree && !hasValidLicence && !isProductFree
    "
    :small="small"
    >Gratis</UBadge
  >
</template>
