import gql from "graphql-tag"

export default gql`
  query MyQuery {
    content {
      pages {
        edges {
          node {
            _location {
              priority
              urlAliases(custom: true) {
                path
              }
            }
            title
          }
        }
      }
    }
  }
`
