<script setup lang="ts">
import type { Subject } from "~/types/subject.d"

const store = useProductContentStore()
const licence = useLicenceStore()
const route = useRoute("product")

const licenceData = computed(() => licence.licenceData)
const { dataAssigned: licenceAssigned } = useDataWatch(licenceData)

const getChaptersInFolder = (id: number) => {
  return store.subjects
    .filter(i => i.parentLocationId === id)
    .sort((a, b) => a.priority - b.priority)
}

const checkChapterNotInFolders = (parentLocationId: number) => {
  return (
    store.folders.filter(i => i.id === parentLocationId).length === 0
  )
}

const chaptersNotInFolders = computed(() => {
  const chapters: Subject[] = []
  store.subjects.forEach(i => {
    if (checkChapterNotInFolders(i.parentLocationId)) {
      chapters.push(i)
    }
  })

  return chapters.sort((a, b) => a.priority - b.priority)
})

function restoreHashScroll() {
  if (!location.hash) return

  document.querySelector(location.hash)?.scrollIntoView()
}

// This watcher follows the v-if condition of the <ul>
watch(
  () => store.subjects.length || store.folders.length,
  () => {
    // A timeout is needed as the elements are not yet in DOM, not even after nextTick().
    setTimeout(() => restoreHashScroll(), 500)
  },
)

onMounted(() => {
  restoreHashScroll()
})
</script>

<template>
  <div>
    <ul
      v-if="store.subjects.length || store.folders.length"
      id="chapter-cards"
      :key="0"
    >
      <li class="mb-11">
        <u-continue v-if="!route.hash" />
      </li>
      <li
        v-for="(folder, key) in store.folders"
        :id="`chapter-card-list-item-${folder.id}`"
        :key="key"
      >
        <UFolderCard
          :chapters="getChaptersInFolder(folder.id)"
          :folder="folder"
          :licence="licenceAssigned"
        />
      </li>
      <li
        v-for="(chapter, key) in chaptersNotInFolders"
        :id="`chapter-card-list-item-${chapter.id}`"
        :key="key"
      >
        <UChapterCard
          :chapter="chapter"
          :licence="licenceAssigned"
          class="mb-10 lg:mb-14"
        />
      </li>
    </ul>

    <u-skeleton-card-list v-else :key="1" />
  </div>
</template>

<route lang="yaml">
name: product
meta:
  layout: product
</route>
