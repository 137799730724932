import type { HomepageIbexaData } from "~/types/ibexa.pages"

import GraphqlLandingPageDocument from "~/graphql/documents/page"
import { graphqlClient } from "~/graphql/client"
import type { PageData } from "~/types/pages"
import { formatVideoData } from "../article/create-video"

export default async (urlAlias: string): Promise<PageData | null> => {
  const { data } = (await graphqlClient({
    query: GraphqlLandingPageDocument,
    variables: {
      urlAlias,
    },
  })) as HomepageIbexaData

  const query = data._repository.location

  if (!query) return null

  const { id, priority, content, urlAliases } = query
  const {
    title,
    subtitle,
    metaImage,
    metaTitle,
    metaDescription,
    layout,
  } = content

  const children = content._location.children.edges.map(
    ({ node }) => node,
  )

  const sections = children.map(i => {
    return {
      id: i.id,
      title: i.content.title,
      priority: i.priority,
      image: {
        src: i.content?.image?.variation?.uri,
        alt: i.content?.image?.alternativeText,
      },
      video: i.content.video && formatVideoData(i.content?.video),
      richtext: i.content.richtext.html5,
    }
  })

  return {
    id,
    priority,
    title,
    urlAliases,
    subtitle,
    metaImage: {
      src: metaImage?.variation?.uri,
      alt: metaImage?.alternativeText,
    },
    route: {
      name: title.toLowerCase(),
      path: `/${title.toLocaleLowerCase().replace(/ /g, "-")}`,
    },
    sections,
    metaTitle,
    metaDescription,
    layout,
  }
}
