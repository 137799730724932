<script setup lang="ts">
import { useBackgroundColor } from "~/composables/useBackgroundColor"

const route = useRoute<"product">()
const productContent = useProductContentStore()
const products = useProductVariantsStore()

const backgroundColor = useBackgroundColor()
backgroundColor.value = "var(--theme-darkest)"
</script>

<template>
  <u-feature-flag
    class="fixed top-0 left-0 z-50 p-4 text-gray-400 w-fit"
  >
    <UButtonNew to="/" variant="quite" size="sm" theme="white"
      >kunne.no</UButtonNew
    >
  </u-feature-flag>

  <div
    class="flex w-full items-center justify-center bg-[--theme-darkest]"
  >
    <div
      class="flex flex-col max-w-[1465px] items-center justify-center w-full px-9 lg:px-20 md:flex-row md:items-start md:gap-16"
    >
      <u-side-nav
        :data="productContent.subjectNav"
        :product="products.activeVariant"
        :folders="productContent.folders"
        :licence="products.activeVariant?.userHasLicence"
        page-type="product"
      />
      <main class="w-full">
        <UMyProfileButton
          :path="[route.params.product, route.params.variant]"
        />
        <router-view />
      </main>
      <UMarketingBanner />
    </div>
  </div>
</template>
