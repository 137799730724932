import * as Sentry from "@sentry/vue"
import { api } from "~/axios"

export async function get(key: string): Promise<unknown> {
  try {
    const data = await api.external.get(
      `/rest/frontend/metadata/${key}`,
    )
    if (!data.data?.value) {
      const axiosError = data as unknown as {
        response: { status: number }
      }
      if (axiosError.response.status !== 404) {
        throw new Error(
          `Error fetching key value: ${JSON.stringify(axiosError)}`,
        )
      }
      return undefined
    }
    return JSON.parse(data.data.value)
  } catch (error) {
    Sentry.captureException(error)
    return undefined
  }
}
