import type { Ref } from "vue"

export function useDataWatch<Type>(rawData: Ref<Type[] | null>): {
  dataAssigned: Ref<boolean>
  data: Ref<Type[]>
} {
  const dataAssigned = ref(false)
  const data: Ref<Type[]> = ref([])

  if (Array.isArray(rawData.value)) {
    data.value = rawData.value
    dataAssigned.value = rawData.value.length > 0
    return { dataAssigned, data }
  }

  watch(rawData, () => {
    if (!rawData.value) {
      dataAssigned.value = false
    } else {
      data.value = rawData.value
      dataAssigned.value = data.value.length > 0
    }
  })
  return { dataAssigned, data }
}
